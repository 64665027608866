<template>
  <div class="left-meeting">
    <h1>You left the meeting</h1>
    <h2>Thank you for using Sidekey!</h2>
    <p>
      If you left the meeting accidentally use Rejoin button to return to the meeting
    </p>
    <div class="left-meeting__buttons">
      <div class="button button__border" @click="onRejoinClick">Rejoin</div>
      <div class="button" @click="onNewMeetingClick">Create new meeting</div>
    </div>
    <section class="left-meeting__banner">
      <div class="left-meeting__banner_info">
        <h3 class="left-meeting__banner_title">Support Sidekey</h3>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore
        </p>
      </div>
      <div class="button button__border_light">Buy Sidekey Token</div>
    </section>
    <section class="left-meeting__social">
      <h2>Follow Us</h2>
      <div class="left-meeting__social_list">
        <a href="" class="left-meeting__social_button facebook" target="_blank"></a>
        <a href="" class="left-meeting__social_button instagram" target="_blank"></a>
        <a href="" class="left-meeting__social_button linkedin" target="_blank"></a>
        <a href="" class="left-meeting__social_button twitter" target="_blank"></a>
        <a href="" class="left-meeting__social_button youtube" target="_blank"></a>
      </div>
    </section>
  </div>
</template>

<script>

import router from "@/router";

export default {
  name: 'LeftMeeting',
  components: {
  },
  methods:{
    onNewMeetingClick(){
      router.push({ name: 'HomePage'});

    },
    onRejoinClick(){
      // router.push({ name: 'MeetingPage', params: { meetID: this.$route.params.meetID} });
      router.push({name: 'GetReadyPage', params: {meetID: this.$route.params.meetID}});
    },
  }
}
</script>
