'use strict';
import api from "./API";
import {EventEmitter} from 'events';
import Debug from "./Debug";
// import translator from "./Translator";
import Utils from "./Utils";

export const AUTH_STORAGE = 'VTailUser';
/*{
TOKEN: 'vtail_token',
NAME: 'vtail_name',
ID: 'vtail_id',
USER_ID: 'vtail_user_id',
};*/
// export const SECRET_KEY = "b778dafdd8634f00283c76762b8be777";
const SECRET_KEY = process.env.VUE_APP_VTAIL_API_SECRET_KEY;
export let AUTH_USER = {};
export const USER_ROLES = {
    GUEST: 0,
    CLIENT: 1,
};
export const AUTH_EVENTS = {
    AUTH_CLIENT: 'authClient',
    AUTH_FAILED: 'authFailed',
};

const console = new Debug("  Auth  ", "#FFF", "#FF0000");

//const translator = new TranslatorClass();
class AuthClass extends EventEmitter {
    constructor(options = {}) {
        super();
        console.info("constructor", options);
        if (AuthClass.Instance) {
            return AuthClass.Instance;
        }
        AuthClass.Instance = this;

        this.getUser();
        this.signInResolve=()=>{};
        this.signInReject=()=>{};
        this.mediaStreams = null;
    }

    /*createDOM() {
        console.info("createDOM");
        if (document.getElementById("vtail-auth")) return;
        let html = `<form id="vtail-auth-form" class="vtail-auth-form">` +
            `<div class="vtail-auth-form__title">${translator.get('auth_title')}</div>` +
            `<div class="vtail-form-group"><input type="text" name="user_name" placeholder="${translator.get('auth_name_placeholder')}" required></div>` +
            `<input type="button" class="vtail-button vtail-button__border-inverse" value="${translator.get('auth_button_cancel')}">` +
            `<input type="submit" class="vtail-button vtail-button__inverse" value="${translator.get('auth_button_submit')}">` +
            `</form>`;

        let div = document.createElement('div');
        div.setAttribute("id", "vtail-auth");
        div.classList.add("vtail-hidden");
        div.innerHTML = html;
        document.getElementById('vtail').append(div);
        // document.getElementById('vtail-extension').appendChild(div);

        this.authWrapper = document.getElementById("vtail-auth");
        this.authForm = document.getElementById("vtail-auth-form");
        this.authFormName = this.authForm.querySelector("input[name='user_name']");
        this.authFormCancelButton = this.authForm.querySelector(".vtail-button.vtail-button__border-inverse");

        this.authFormCancelButton.addEventListener("click", (event) => { //FIXME
            event.preventDefault();
            this.emit(AUTH_EVENTS.AUTH_FAILED);
            this.signInReject();
            this.hide();
        }, false);
        this.authForm.addEventListener("submit", (event) => {
            event.preventDefault();
            let name = this.authForm.elements["user_name"].value.trim();
            if (name.length) {
                // let api = new API();
                /!*this.sendAuthRequest({
                    "user_name": name,
                    "is_guest": false
                });*!/
                /!*this.signIn({
                    userName: name,
                    asGuest: false,
                    storeUserID: this.storeUserID,
                })
                    .finally(()=>{console.info("FINALLY")})*!/

                this.sendAuthRequest({
                    "user_name": name,
                    "is_guest": false,
                    "store_user_id": this.storeUserID,
                });


                this.storeUserID = null;
            }
        }, false);
    }*/

    sendAuthRequest(authData) {
        authData['secret_key'] = SECRET_KEY;
        // authData['store_user_id'] = AUTH_USER.store_user_id || Utils.uuidv4();
        console.info('sendAuthRequest', authData);
        api.userAuthClient(authData)
            .then(data => {
                console.info("userAuthClient", data);
                this.updateUser({
                    token: data.token,
                    id: data.user.id,
                    name: data.user.user_name,
                    store_user_id: data.user.store_user_id,
                    role: data.user.user_role,
                });
                console.info("AUTH_EVENTS emit", AUTH_EVENTS.AUTH_CLIENT);
                // console.info("this.signInResolve", this.signInResolve);
                this.emit(AUTH_EVENTS.AUTH_CLIENT, AUTH_USER);
                this.signInResolve([AUTH_USER, true]);
                this.hide();
                // location.reload();
            })
            .catch(error => {
                console.error("userAuthClient", error);
                this.signInReject(error);
                this.emit(AUTH_EVENTS.AUTH_FAILED, error);
            })
    }


    setUser(user) {
        console.info("setUser", user);
        AUTH_USER = user;
        // localStorage.setItem(AUTH_STORAGE, JSON.stringify(AUTH_USER));
    }

    updateUser(user) {
        console.info("updateUser", user);
        this.setUser(Object.assign(AUTH_USER, user));
    }

    getUser() {
        console.info("getUser");
        AUTH_USER = JSON.parse(localStorage.getItem(AUTH_STORAGE)) || {};
        if (!AUTH_USER.session_id) {
            this.updateUser({session_id: Utils.uuidv4()})
        }
    }

    signOut() {
        console.info("signOut");
        AUTH_USER = {};
        localStorage.removeItem(AUTH_STORAGE);
    }

    signIn({asGuest = false, userName = null, storeUserID = null}) {
        console.info("signIn", {asGuest, userName, storeUserID}, AUTH_USER);
        if (!storeUserID) {
            storeUserID = AUTH_USER.store_user_id || Utils.uuidv4();
        }
        return new Promise((resolveee, rejecttt) => {
            // console.info("signIn PROMISE ", this.signInResolve, this.signInReject);
            this.signInResolve = resolveee;
            this.signInReject = rejecttt;
            if (AUTH_USER.token
                && (asGuest || (!asGuest && AUTH_USER.role === USER_ROLES.CLIENT))
                && (AUTH_USER.store_user_id === storeUserID)
            ) {
                // resolve(AUTH_USER);
                this.signInResolve([AUTH_USER, false]);
            } else if (asGuest) {
                this.sendAuthRequest({
                    "user_name": userName || null,
                    "is_guest": true,
                    "store_user_id": storeUserID,
                });
            } else if (userName) {
                this.sendAuthRequest({
                    "user_name": userName,
                    "is_guest": false,
                    "store_user_id": storeUserID,
                });
            } else {
                this.storeUserID = storeUserID;
                this.show();
            }


            /*this.on(AUTH_EVENTS.AUTH_CLIENT, (user) => {
                resolve(user);
            });
            this.on(AUTH_EVENTS.AUTH_FAILED, (error) => {
                reject(error);
            });*/
        });
    }

    show() {
        console.info("show");
        if (!this.authWrapper) {
            // this.createDOM();
        }
        this.authForm.reset();
        this.authWrapper.classList.remove("vtail-hidden");
        this.authFormName.focus();
    }

    hide() {
        console.info("hide");
        if (this.authWrapper) {
            this.authWrapper.classList.add("vtail-hidden");
        }
    }
}

const auth = new AuthClass();
export default auth;