/* eslint-disable */
'use strict';
import {EventEmitter} from 'events';
import Debug from "./Debug";
import BroadcastConnection from "./BroadcastConnection";
import ControlButton from "./ControlButton";
import wss, {WSS_CODES} from "./WSS";
import api from './API';
import translator from "./Translator";
import auth, {AUTH_USER} from "./Auth";
import {CLIENT_STATES, CONFIG} from "./VARs";
import Utils from "./Utils";
import {AVATARS64} from "@/vtail/avatars";


const console = new Debug("  PrivateSession  ", "#FFF", "#d195b7");
// const wss = new WSSClass({});
// const api = new APIClass();
//const translator = new TranslatorClass();

const PRELOADER_WRAPPER = 'vtail-preloader-visible';
const MINIPLAYER_WRAPPER = 'vtail-miniplayer';
const PLAYER_CONTROLS_WRAPPER = 'vtail-show-controls';
const PLAYER_LIVE_WRAPPER = 'vtail-live';
const DEFAULT_SESSION_DURATION = 600000;//10*60*1000

const INVITE_LINK = "http://marketplace.vtail.live/share/?broadcast=";
export const PRELOADER_STATES = {
    HIDDEN: "hidden",
    WAITING: 'vtail-preloader-visible-waiting',
    LOADING: 'vtail-preloader-visible-loading',
    UNAVALIABLE: 'vtail-preloader-visible-unavailable',
    DISCONNECT: 'vtail-preloader-visible-disconnect',
    ENDED: 'vtail-preloader-visible-ended',
    PLAYREADY: 'vtail-preloader-visible-playready',
    NOACCESS: 'vtail-preloader-visible-no-access',
    ISOVER: 'vtail-preloader-visible-is-over',
    RESTART: 'vtail-preloader-visible-restart',
    EXIT: 'vtail-preloader-visible-exit',
    WORKING_HOURS: 'vtail-preloader-visible-working-hours',
}

export const PLAYER_ORIENTATION = {
    VERTICAL: 'vtail-vertical',
    HORIZONTAL: 'vtail-horizontal'
};

export const SESSION_STATES = {
    LIVE: 20,
    NOT_LIVE: 0,
    LOADING: 10
};

export const USER_TYPES = {
    CONSULTANT: 'consultant',
    CLIENT: 'client',
    FRIEND: 'friend'
};

export default class PrivateSessionClass extends EventEmitter {
    constructor(options = {}) {
        super();
        this.timestamp = new Date();
        console.info("constructor", options);
        // this.createDOM();
        let urlParams = new URLSearchParams(window.location.search);
        this.IS_PUBLIC_BROADCST = urlParams.has("vtail_broadcast");


        this.localStream = {
            stream: null,
            audioTrack: {},
            audioTrackAnonym: {},
            audioTrackOrigin: {},
            videoTrack: {},
            avatarTrack: {},
            screenShareTrack: null
        };
        this.activeTracks = {
            microphone: false,
            camera: false,
            avatar: false,
            screen: false,
        }
        this.selfViewStream=new MediaStream();
        this.avatarStream = {};
        // this.createAvatarStream(200, 200, avatarBase642);
        this.constraints = {};
        this.mediaDevices = {
            audio: [],
            video: []
        };

        this.onlineUsers = [];

        /*this.takeScreenshot = new TakeScreenshot();
        this.takeScreenshot.on('close', (e) => {
            this.controls.askProductBtn.unsetActive();
        });

        this.takeScreenshot.on('sendProductRequest', (imageBlob) => {
            api.imageUpload(imageBlob)
                .then(data => {
                    let product = {
                        image_url: data.url
                    };
                    this.sendProductRequest(product);
                })
                .catch((error) => {
                    console.error('imageUpload', error);
                })
        });*/


        /*wss.on(WSS_CODES.OFFER, this.onOffer=data => {
            this.onOffer(data);
        });
        wss.on(WSS_CODES.ONLINE_USERS, data => {
            this.onOnlineUsers(data.users);
        });
        wss.on(WSS_CODES.ONLINE_USER, data => {
            this.onOnlineUser(data);
        });
        wss.on(WSS_CODES.OFFLINE_USER, data => {
            this.onOfflineUser(data);
        });
        wss.on(WSS_CODES.BROADCAST_FINISH, data => {
            this.onBroadcastFinish(data);
        });*/
        this.onOffer = this.onOffer.bind(this);
        this.onOnlineUsers = this.onOnlineUsers.bind(this);
        this.onOnlineUser = this.onOnlineUser.bind(this);
        this.onOfflineUser = this.onOfflineUser.bind(this);
        this.onBroadcastFinish = this.onBroadcastFinish.bind(this);
        this.onWSSclose = this.onWSSclose.bind(this);
        this.onWSSreconnect = this.onWSSreconnect.bind(this);

        wss.on(WSS_CODES.OFFER, this.onOffer);
        wss.on(WSS_CODES.ONLINE_USERS, this.onOnlineUsers);
        wss.on(WSS_CODES.ONLINE_USER, this.onOnlineUser);
        wss.on(WSS_CODES.OFFLINE_USER, this.onOfflineUser);
        wss.on(WSS_CODES.BROADCAST_FINISH, this.onBroadcastFinish);
        wss.on("onclose", this.onWSSclose);
        wss.on("onreconncet", this.onWSSreconnect);

        /*this.draggableMouseDown = this.draggableMouseDown.bind(this);
        this.draggableMouseMove = this.draggableMouseMove.bind(this);
        this.draggableMouseUp = this.draggableMouseUp.bind(this);
        this.draggablePosition = {};*/


        this.broadcast = null;
        this.controls = [];

        this.STATE = SESSION_STATES.NOT_LIVE;
        // this.preloaderState = PRELOADER_STATES.LOADING;
        this.preloaderState = null;
        this.preloaderStateHistory = PRELOADER_STATES.LOADING;

        this.playerOrientation = options.playerOrientation || PLAYER_ORIENTATION.VERTICAL;
        this.miniplayerOn = options.miniplayerOn || false;
        this.showVideoControls = options.showVideoControls || true;
        this.liveTour = options.liveTour || true;

        this.volumeControl = null;
        this.progressBar = null;

        /*this.productsList = new ProductsListClass();
        this.productsList.on("onSetCurrentProduct", e => {
            console.info("onSetCurrentProduct", e);
        });
        this.productsList.on("onProductClick", product => {
            console.info("onProductClick", product);
            // this.emit("productDetail", product.store_product_id);
            this.emit("productDetail", product);
            // this.minimizePlayer();

        });
        this.productsList.on("onHideProductsList", e => {
            console.info("onHideProductsList", e);
            this.controls.productsListBtn.unsetActive();
        });*/


        this.playerWrapper = document.getElementById("vtail-player");
        // let vtailPlayer = document.getElementById("vtail-video-canvas");
        this.videoClient = document.getElementById("vtail-player-video-local");
        this.videoStreamer = document.getElementById("vtail-player-video-remote");
        this.videoSelfview = document.getElementById("jeelizFaceExpressionsCanvas");
        this.audioElemnts = [];
        this.vtailAlert = document.querySelector('.vtail-alert');
        this.draggableHeader = document.querySelector('.vtail-miniplayer-controls');

        this.connections = {};


        /*this.controls.overlayBtn = new ControlButton(this.playerWrapper.querySelector(".vtail-overlay"));
        this.controls.overlayBtn.on("click", this.onOverlayClick.bind(this));
        this.controls.overlayBtn.hide();

        this.controls.closeBtn = new ControlButton(this.playerWrapper.querySelector(".vtail-player-button_close"));
        this.controls.closeBtn.on("click", this.onClose.bind(this));
        this.controls.closeBtn.hide();*/

        /*this.playerWrapper.querySelectorAll(".vtail-close-confirm").forEach(element => {
            element.addEventListener('click', this.onConfirmClose.bind(this), false)
        });
        this.playerWrapper.querySelectorAll(".vtail-close-deny").forEach(element => {
            element.addEventListener('click', this.onDenyClose.bind(this), false)
        });*/

        // this.controls.confirmCloseBtn = new ControlButton(this.vtailContainer.querySelectorAll(".vtail-close-confirm"));
        // this.controls.confirmCloseBtn.on("click", this.onConfirmClose.bind(this));

        // this.controls.denyCloseBtn = new ControlButton(this.vtailContainer.querySelector(".vtail-close-deny"));
        // this.controls.denyCloseBtn.on("click", this.onDenyClose.bind(this));
        this.isResolveBroadcastRequest = false;


        // this.initProductsList();
        // this.initAlerts();
        this.initControls();

        this.setPreloaderState(PRELOADER_STATES.LOADING);
        /*if (options.requestSession) {
            if (Utils.isWorkingHoursNow(CONFIG.WORKING_HOURS)) {
                this.sendBroadcastRequest(options.requestSession)
            } else {
                this.drawWorkingHours();
            }
        } else {
            this.checkClientState();
            // this.getBroadcast();
        }*/
        // this.show();
        this.initState = options.initState;
        this.getBroadcast(options.broadcastID);

        // this.askToClosePromiseResolve=()=>{};
        // this.initPlayer(); //FIXME temp solution
    }

    get getPlayerOrientation() {
        return this.playerOrientation;
    }

    /*WORKING HOURS*/ //FIXME move to separate class or utils

    get getMiniplayerMode() {
        return this.miniplayerOn
    }

    get getVideoControls() {
        return this.showVideoControls
    }

    get getLiveTourState() {
        return this.liveTour
    }

    createDOM() {
        if (document.getElementById("vtail-player")) return;
        // document.body.classList.add("vtail-body-wrap");
        let html = //`<div id="vtail-player" class="vtail-wrapper vtail-vertical vtail-preloader-visible  vtail-hidden">` +
            `        <div class="vtail-overlay" hidden></div>` +
            // `        <div class="vtail-products">` +
            // `            <div class="vtail-products__close">Показанные товары</div>` +
            // `            <div class="vtail-products__content"></div>` +
            // `        </div>` +
            // `        <div class="vtail-player">` +
            `            <div class="vtail-player-video-wrap">` +
            `                <div class="vtail-player-video">` +
            `                    <div class="sidekey-guid sidekey-guid__partner"></div>` +
            `                    <div class="sidekey-guid sidekey-guid__me"></div>` +
            // `                    <video id="vtail-player-video-remote" class="vtail-video_remote" playsinline autoplay></video>` +
            // `                    <video id="vtail-player-video-local" class="vtail-video_local vtail-hidden" playsinline autoplay muted></video>` +
            `                    <div class="sidekey-stream-mask-wrap"><canvas id="canvasWeboji" class="sidekey-stream-mask"></canvas></div>` +
            // `                    <canvas id="jeelizFaceExpressionsCanvas" class="sidekey-stream-user"></canvas>` +
            `                </div>` +
            // `                <div class="vtail-player-product"> <!--текущий товар-->` +
            // `                    <div class="vtail-player-product__image">` +
            // `                        <img src="" alt=""/>` +
            // `                    </div> <!--фото товар-->` +
            // `                    <!--<div class="vtail-player-product__title"></div> &lt;!&ndash;название товар, пока нет, но может быть&ndash;&gt;&lt;!&ndash;втророй приоритет&ndash;&gt;-->` +
            // `                    <!--<div class="vtail-player-product__price"></div> &lt;!&ndash;цена товар&ndash;&gt;-->` +
            // `                    <!--<div class="vtail-player-product__discount"></div> &lt;!&ndash;скидка товар&ndash;&gt;-->` +
            // `                </div>` +
            `                <div class="vtail-player-controls"><!--нижняя часть для контролов-->` +
            // `                    <!--общий прогрессбар-->` +
            // `                    <!--<div class="vtail-progressbar">` +
            // `                        <div class="vtail-progressbar__inner"></div>` +
            // `                    </div>-->` +
            `                    <div class="vtail-player-buttons"> <!--кнопки плеера-->` +
            // `                        <div class="vtail-player-buttons__left"> <!--левая часть-->` +
            // `                            <!--громкость-->` +
            // `                            <!--<div class="vtail-player-volume">` +
            // `                                <div class="vtail-player-button vtail-player-volume__button"></div> &lt;!&ndash;значек уровня кгомкости + мюте&ndash;&gt;` +
            // `                                <div class="vtail-player-volume__slider vtail-rangeslider"></div> &lt;!&ndash;ползунок громкости&ndash;&gt;` +
            // `                            </div>-->` +
            // `` +
            `                            <div class="vtail-player-button vtail-player-button_microphone vtail-mic-turn-on" title="${translator.get('private_button_mic_on')}"></div>` +
            `                            <div class="vtail-player-button vtail-player-button_microphone vtail-mic-turn-off" title="${translator.get('private_button_mic_off')}"></div>` +
            // `` +
            `                            <div class="vtail-player-button vtail-player-button_camera vtail-camera-turn-off" title="${translator.get('private_button_camera_on')}"></div>` +
            `                            <div class="vtail-player-button vtail-player-button_camera vtail-camera-turn-on" title="${translator.get('private_button_camera_off')}"></div>` +
            // `` +
            `                            <div class="vtail-player-button vtail-player-button_share-screen vtail-screen-share-on" title="${translator.get('private_button_share_on')}"></div>` +
            `                            <div class="vtail-player-button vtail-player-button_share-screen vtail-screen-share-off" title="${translator.get('private_button_share_off')}"></div>` +
            // `` +
            `                            <div class="vtail-player-button vtail-player-button_ask-product" title="${translator.get('private_button_askProduct')}"></div>` +
            // `` +
            `                            <div class="vtail-player-button vtail-player-button_invite" title="${translator.get('private_button_invite')}"></div>` +
            `                            <div class="vtail-player-button vtail-player-button_voice-end" title="${translator.get('private_button_close')}"></div>` +
            // `` +
            //`                            <div class="vtail-player-button vtail-player-button_products-list" title="${translator.get('private_button_products')}"></div>` +
            // `                            <!--список продуктов-->` +
            // `` +
            // `                            <!--в прям эфире-->` +
            // `                            <!--<div class="vtail-player-time">` +
            // `                                <div class="vtail-player-time__live">Live</div>` +
            // `                            </div>-->` +
            // `                        </div>` +
            // `                        <!--контролы справа-->` +
            // `                        <!--<div class="vtail-player-buttons__right">` +
            // `                            <div class="vtail-player-button vtail-player-button_ask-product" title="Ask product"></div>&lt;!&ndash;запросить товар по кадру с видео&ndash;&gt;` +
            // `                            <div class="vtail-player-button vtail-player-button_product-details" title="Product details"></div>&lt;!&ndash;перейти на страницу текущего продукта&ndash;&gt;` +
            // `                            <div class="vtail-player-button vtail-player-button_miniplayer" title="Miniplayer"></div>&lt;!&ndash;войти / выйти мини плеер&ndash;&gt; &lt;!&ndash;втророй приоритет&ndash;&gt;` +
            // `                        </div>-->` +
            `                    </div>` +
            `                </div>` +
            /*`                <div class="vtail-preloader">` +
            `                    <div class="vtail-preloader__box vtail-preloader__cover">` +
            `                        <div class="vtail-preloader__img"></div>` +
            `                    </div>` +
            `                    <div class="vtail-preloader__box vtail-preloader__waiting">` +
            `                        <div class="vtail-preloader__img"></div>` +
            `                        <div class="vtail-preloader__txt">Waiting for broadcasting</div>` +
            `                    </div>` +
            `                    <div class="vtail-preloader__box vtail-preloader__loading">` +
            `                        <div class="vtail-preloader__img"></div>` +
            // `                        <div class="vtail-preloader__txt">Скоро подключится консультант...</div>` +
            `                        <div class="vtail-preloader__txt">${translator.get('private_preloader_loading')}</div>` +
            `                    </div>` +
            `                    <div class="vtail-preloader__box vtail-preloader__unavailable">` +
            `                        <div class="vtail-preloader__img"></div>` +
            `                        <div class="vtail-preloader__txt">Video will be available soon</div>` +
            `                    </div>` +
            `                    <div class="vtail-preloader__box vtail-preloader__disconnect">` +
            `                        <div class="vtail-preloader__img">` +
            `                            <div class="vtail-preloader__img_plug"></div>` +
            `                            <div class="vtail-preloader__img_plug"></div>` +
            `                        </div>` +
            `                        <div class="vtail-preloader__txt">${translator.get('private_preloader_disconnect')}</div>` +
            `                    </div>` +
            `                    <div class="vtail-preloader__box vtail-preloader__ended">` +
            `                        <div class="vtail-preloader__img"></div>` +
            `                        <div class="vtail-preloader__txt">The end of the broadcasting</div>` +
            `                    </div>` +
            `                    <div class="vtail-preloader__box vtail-preloader__playready">` +
            `                        <div class="vtail-preloader__img"></div>` +
            `                    </div>` +
            `                    <div class="vtail-preloader__box vtail-preloader__mic-cam-access">` +
            `                        <div class="vtail-preloader__title">${translator.get('private_preloader_mic_access')}</div>` +
            `                        <div class="vtail-preloader__txt">${translator.get('private_preloader_mic_access_text')}</div>` +
            `                        <div class="vtail-preloader__btn vtail-button vtail-close-confirm">${translator.get('private_preloader_mic_access_button')}</div>` +
            `                    </div>` +
            `                    <div class="vtail-preloader__box vtail-preloader__session-is-over">` +
            // `                        <!--<div class="vtail-preloader__pic vtail-preloader__pic_hunlemoller"></div>-->` +
            `                        <div class="vtail-preloader__title">${translator.get('private_preloader_over')}</div>` +
            // `                        <div class="vtail-preloader__btn vtail-button vtail-close-confirm">OK</div>` +
            // `                <div class="vtail-form-group vtail-subscribe-btn_wrap">` +
            // `                   <a class="vtail-widget-subscribe-close vtail-subscribe-btn vtail-subscribe-btn_viber" title="Viber"></a>` +
            // `                   <a class="vtail-widget-subscribe-close vtail-subscribe-btn vtail-subscribe-btn_whatsup" title="WhatsUp"></a>` +
            // `                   <a class="vtail-widget-subscribe-close vtail-subscribe-btn vtail-subscribe-btn_telegram" title="Telegram"></a>` +
            // `               </div>` +
            `                    </div>` +
            `                    <div class="vtail-preloader__box vtail-preloader__confirm-close">` +
            // `                        <!--<div class="vtail-preloader__pic vtail-preloader__pic_hunlemoller"></div>-->` +
            // `                        <div class="vtail-preloader__pic vtail-preloader__pic_logo"></div>` +
            `                        <div class="vtail-preloader__title">${translator.get('private_preloader_confirmExit')}</div>` +
            `                        <div class="vtail-preloader__btn vtail-button vtail-button__border vtail-close-deny">${translator.get('private_preloader_confirmExit_button_no')}</div>` +
            `                        <div class="vtail-preloader__btn vtail-button vtail-close-confirm">${translator.get('private_preloader_confirmExit_button_yes')}</div>` +
            `                    </div>` +
            `                    <div class="vtail-preloader__box vtail-preloader__restart">` +
            // `                        <!--<div class="vtail-preloader__pic vtail-preloader__pic_hunlemoller"></div>-->` +
            // `                        <div class="vtail-preloader__pic vtail-preloader__pic_logo"></div>` +
            `                        <div class="vtail-preloader__title">` +
            `                            <div>${translator.get('private_preloader_restart')}</div>` +
            `                        </div>` +
            `                        <div class="vtail-preloader__btn vtail-button vtail-close-confirm">${translator.get('private_preloader_restart_button_ok')}</div>` +
            `                    </div>` +
            `                    <div class="vtail-preloader__box vtail-preloader__working-hours">` +
            `                        <div class="vtail-preloader__title">${translator.get('working_hours_title')}</div>` +
            `                        <div class="vtail-preloader__btn vtail-button vtail-close-confirm">${translator.get('private_preloader_working_hours_close')}</div>` +
            `                    </div>` +
            `                </div>` +*/
            `                <div class="vtail-alert vtail-hidden"></div>` +
            `                <div class="vtail-miniplayer-controls">` +
            `                    <div class="vtail-player-button vtail-player-button_miniplayer" title="${translator.get('private_button_minimize')}"></div>` +
            // `                    <!-- войти / выйти мини плеер -->` +
            `                    <div class="vtail-player-time"></div>` +
            `                    <div class="vtail-player-button vtail-player-button_close" title="${translator.get('private_button_close')}"></div>` +
            // `                    <!-- закрыть трансляцию -->` +
            `                </div>` +
            `            </div>` +
            /*`            <div class="vtail-request-product vtail-hidden">` +
            `                <div class="vtail-request-product__content">` +
            `                    <div class="vtail-request-product__close"></div>` +
            `                    <div class="vtail-request-product__area">` +
            `                        <canvas class="vtail-request-product-frame"></canvas>` +
            `                        <canvas class="vtail-request-product-pointer"></canvas>` +
            `                    </div>` +
            `                    <div class="vtail-request-product__label">Кликнете по интересующему Вас товару.</div>` +
            `                    <div class="vtail-request-product__button disabled">Отправить</div>` +
            `                </div>` +
            `            </div>` +*/
            // `        </div>` +
            // `    </div>` +
            ``;

        this.playerWrapper = document.createElement('div');
        // this.playerWrapper = document.getElementById('sidekey-stream-wrap');
        this.playerWrapper.setAttribute('id', 'vtail-player');
        this.playerWrapper.classList.add('vtail-wrapper', 'vtail-vertical', 'vtail-preloader-visible', 'vtail-hidden');
        this.playerWrapper.innerHTML = html;
        // document.body.appendChild(div);
        document.getElementById('sidekey-stream-wrap').appendChild(this.playerWrapper);

        this.playerVideoWrapper = this.playerWrapper.querySelector(".vtail-player-video-wrap");
        this.controlsButtonsWrapper = this.playerVideoWrapper.querySelector('.vtail-player-buttons');
    }

    /*WORKING HOURS*/

    checkWorkingHours() {
        if (CONFIG.WORKING_HOURS) {
            let date = new Date();
            let dayOfWeek = date.getUTCDay();
            let workingDay = CONFIG.WORKING_HOURS[dayOfWeek];
            if (workingDay) {
                console.info("workingDay", workingDay);
                let time = workingDay.open.split(":");
                let openMinutes = parseInt(time[0]) * 60 + parseInt(time[1]);
                time = workingDay.close.split(":");
                let closeMinutes = parseInt(time[0]) * 60 + parseInt(time[1]);
                console.info(date.toUTCString());
                let nowMinutes = date.getUTCHours() * 60 + date.getUTCMinutes();
                console.info("checkWorkingHours", nowMinutes, openMinutes, closeMinutes);
                return nowMinutes >= openMinutes && nowMinutes < closeMinutes;
            } else {
                return false;
            }
        }
        return true
    }

    drawWorkingHours() {
        console.info("drawWorkingHours", CONFIG.WORKING_HOURS);
        // this.workingHoursContent = document.createElement('div');
        // this.workingHoursContent.classList.add('vtail-widget__modal_content', 'vtail-widget-working-hours');

        let ul = document.createElement('ul');
        // ul.classList.add('vtail-widget__schedule')
        ul.classList.add('vtail-preloader__schedule')

        let baseDate = new Date();
        let n = 0;
        let schedule = {};
        while (n < 7) {
            n++;
            let dayWeek = new Date(baseDate.getTime());
            let dayOfWeek = Utils.getEnWeekDayName(dayWeek);
            schedule[dayOfWeek] = {};
            schedule[dayOfWeek].name = translator.getWeekday(dayWeek);
            if (CONFIG.WORKING_HOURS.week[dayOfWeek] && CONFIG.WORKING_HOURS.week[dayOfWeek]['from'] && CONFIG.WORKING_HOURS.week[dayOfWeek]['to']) {
                let dayHours = Utils.getDayWorkingHours(dayWeek, CONFIG.WORKING_HOURS);
                schedule[dayOfWeek].text = `${this.getLocalTime(dayHours['open'])} - ${this.getLocalTime(dayHours['close'])}`
            } else {
                schedule[dayOfWeek].text = translator.get('working_hours_closed');
            }
            baseDate.setDate(baseDate.getDate() + 1);
        }
        let sorterArray = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"];
        sorterArray.forEach(day => {
            let li = document.createElement('li');
            let dayNameSpan = document.createElement('span');
            dayNameSpan.textContent = schedule[day]['name'];
            dayNameSpan.classList.add('vtail-preloader__schedule_day');
            let timeSpan = document.createElement('span');
            timeSpan.textContent = schedule[day]['text'];
            timeSpan.classList.add('vtail-preloader__schedule_working-time');

            li.append(dayNameSpan, timeSpan);
            ul.append(li)
        });


        /*
        baseDate.setUTCDate(baseDate.getUTCDate() - baseDate.getUTCDay()+1); // set as Monday
        console.info(baseDate);
        for (let i = 1; i <= 7; i++) {
            let dayOfWeek = (i < 7) ? i : 0;
            console.info(CONFIG.WORKING_HOURS[dayOfWeek]);
            let li = document.createElement('li');
            let dayNameEn = Intl.DateTimeFormat('en', { weekday: 'long' }).format(baseDate);
            let dayNameSpan = document.createElement('span');
            dayNameSpan.textContent = translator.get('working_hours_'+dayNameEn.toLowerCase());
            dayNameSpan.classList.add('vtail-preloader__schedule_day');
            baseDate.setDate(baseDate.getDate() + 1);

            let timeSpan = document.createElement('span');
            if (CONFIG.WORKING_HOURS[dayOfWeek]){
                // timeSpan.textContent = `${CONFIG.WORKING_HOURS[dayOfWeek]['open']} - ${CONFIG.WORKING_HOURS[dayOfWeek]['close']}`
                timeSpan.textContent = `${this.convertToLocalTime(CONFIG.WORKING_HOURS[dayOfWeek]['open'])} - ${this.convertToLocalTime(CONFIG.WORKING_HOURS[dayOfWeek]['close'])}`
            } else {
                timeSpan.textContent = translator.get('working_hours_closed');
            }
            timeSpan.classList.add('vtail-preloader__schedule_working-time');

            li.append(dayNameSpan, timeSpan);
            ul.append(li)
        }
        // workingHours.append(ul);
        */
        this.playerVideoWrapper.querySelector('.vtail-preloader__working-hours .vtail-preloader__title').after(ul);
        this.setPreloaderState(PRELOADER_STATES.WORKING_HOURS);
    }

    getLocalTime(date) {
        let options = {
            hour: '2-digit', minute: '2-digit', hourCycle: 'h23',
        };
        return Intl.DateTimeFormat('default', options).format(date);
    }

    convertToLocalTime(string) {
        console.info("convertToLocalTime", string);
        let time = string.split(":");
        let date = new Date();
        date.setUTCHours(parseInt(time[0]));
        date.setUTCMinutes(parseInt(time[1]));
        return `${date.getHours().toLocaleString('en-US', {
            minimumIntegerDigits: 2,
            useGrouping: false
        })}:${date.getMinutes().toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false})}`
    }

    /*hide() {
        this.playerWrapper.classList.add('vtail-hidden');
    }

    show() {
        this.playerWrapper.classList.remove('vtail-hidden');
    }*/

    onClose() {
        console.info("onClose");
        if (this.STATE > SESSION_STATES.NOT_LIVE || this.preloaderState == PRELOADER_STATES.EXIT) {
            this.setPreloaderState(PRELOADER_STATES.EXIT);
        } else {
            this.close();
        }
    }

    onConfirmClose() {
        console.info("onConfirmClose");
        if (this.askToClosePromiseResolve) {
            this.askToClosePromiseResolve(); //FIXME check if need promise or direct close
        } else {
            this.close();//FIXME check if need promise or direct close
        }


        // this.destroy();
    }

    onDenyClose() {
        console.info("onDenyClose");
        this.setPreloaderState(PRELOADER_STATES.HIDDEN, true);
    }

    askToClose() {
        console.info("askToClose");
        this.setPreloaderState(PRELOADER_STATES.EXIT);
        this.restorePlayer();
        return new Promise((resolve) => {
            this.askToClosePromiseResolve = resolve;
        });
    }

    onOverlayClick() {
        console.info("onOverlayClick");
        // if (this.STATE === SESSION_STATES.LIVE) this.minimizePlayer();
        this.minimizePlayer();
    }

    sendBroadcastRequest(request) {
        console.info("sendBroadcastRequest", request);
        api.broadcastNewPrivateBroadcastRequest(request)
            .then(data => {
                console.info("broadcastNewPrivateBroadcastRequest Data", data);
                this.emit('sessionInit');
                this.getClientState();
                // this.getBroadcast();
            })
            .catch(error => {
                console.log("broadcastNewPrivateBroadcastRequest Error", error);
                switch (error.errorCode) {
                    case 401:
                        this.emit("userNotFound", {requestSession: request});
                        break;
                    default:
                        this.setPreloaderState(PRELOADER_STATES.RESTART);
                }
            });
    }

    checkClientState() {
        console.info("checkClientState", AUTH_USER.state, CLIENT_STATES);
        switch (AUTH_USER.state) {
            case CLIENT_STATES.NEW:
                this.setPreloaderState(PRELOADER_STATES.RESTART);
                break;
            case CLIENT_STATES.REQUEST_ASAP:
                this.setPreloaderState(PRELOADER_STATES.LOADING);
                this.getClientStateTimout = setTimeout(() => {
                    this.getClientState();
                }, 3000);
                break;
            case CLIENT_STATES.PRIVATE_SESSION:
                this.getBroadcast();
                break;
        }
    }

    getClientState() {
        api.userGetClientState()
            .then(data => {
                console.info("userGetClientState", data);
                if (data.state !== AUTH_USER.state) {
                    auth.updateUser({
                        state: data.state,
                        ...(data.request_time_broadcast && {requestTime: data.request_time_broadcast})
                    });
                }
                this.checkClientState();
            })
            .catch(error => {
                console.error("userGetClientState", error);
            })
    }

    getBroadcast(broadcastID) {
        console.info("getBroadcast", broadcastID);
        api.broadcastsGetPrivateBroadcast(broadcastID)
        // api.webRtcGetConfig()
            .then(data => {
                console.info("broadcastsGetPrivateBroadcast", data);
                if (data) {
                    this.initLocalStream()
                        .then(() => {
                            // this.turnOffSelfView();
                            this.createStream();
                            return this.setBroadcast(data);
                        })
                        .then(() => {
                            // this.createConnectionsForOnlineUsers();
                        });

                } else {

                    // console.info("this.preloaderState", this.preloaderState)

                    if (this.preloaderState !== PRELOADER_STATES.EXIT) {
                        //     this.setPreloaderState(PRELOADER_STATES.LOADING); //FIXME set correct state
                    }
                    /*this.getBroadcastTimout = setTimeout(() => {
                        this.getBroadcast();
                    }, 1000);*/
                }
            })
            .catch(error => {
                console.error("getBroadcast Error", error);
                switch (error.errorCode) {
                    case 401:
                        this.emit("userNotFound", {});
                        break;
                    default:
                        this.setPreloaderState(PRELOADER_STATES.RESTART)
                }
            })
    }

    setBroadcast(data) {
        console.info("setBroadcast", data);
        return new Promise((resolve, reject) => {
            /*this.broadcast={
                id:this.broadcastID,
                wssEventsURL:data.wss_url,
                config:{
                    turns:data.turns
                },
            };*/


            console.info("this.initState", this.initState)

            if (this.initState.muted){
                this.turnOffMicrophone();
            } else {
                this.turnOnMicrophone();
            }

            if (this.initState.anonym){
                this.turnOffSelfView();
            } else {
                this.turnOnSelfView();
            }


            this.broadcast = {
                id: data.broadcast.id,
                wssEventsURL: data.wss_events_url,
                // wssEventsURL: "wss://meet.sidekey.com/live/545ab4c3-9823-4578-bfc2-e8609d6b1eab/c1ec849b-117a-47da-b02c-09e06c167405",
                config: data.config,
                isLive: true,
                isPrivate: true
            };
            this.isResolveBroadcastRequest = true
            console.info("setBroadcast this.broadcast", this.broadcast);
            if (this.productsList) this.productsList.setBroadcast(this.broadcast);
            wss.connect(this.broadcast.wssEventsURL);
            resolve();
        })
    }

    initLocalStream() {
        return new Promise((resolve, reject) => {
            // console.info("initLocalStream AUTH_USER", AUTH_USER.mediaStream)
            // console.info("initLocalStream AUTH_USER", AUTH_USER)

            this.enumerateDevices()
                .then(() => {
                    console.info("this.enumerateDevices", this.constraints);
                    if (this.constraints.audio) {
                        this.getUserMedia()
                            .then(mediaStream => {
                                let avatarBase64 = AVATARS64[Math.floor(Math.random()*4)];
                                return this.createAvatarStream(500, 500, avatarBase64);
                            })
                            .then(() => {
                                resolve();
                            })
                            .catch(error => {
                                this.setPreloaderState(PRELOADER_STATES.NOACCESS);
                                // alert(error.name + ' : ' + error.message);
                                console.error("getUserMedia", error)
                            })
                    } else {
                        this.setPreloaderState(PRELOADER_STATES.NOACCESS);
                    }
                })
                .catch(error => {
                    // alert(error.name + ' : ' + error.message);
                    console.error("enumerateDevices", error.name, error.message, error)
                    this.setPreloaderState(PRELOADER_STATES.NOACCESS);
                });
        });
    }

    //--- PLAYER PRELOADER STATE: END ---//

    createAvatarStream(width, height, imageSrc) {
        return new Promise((resolve, reject) => {
            console.info("createAvatarStream");
            this.avatarStream.image= new Image();
            // this.avatarStream.image.crossOrigin="anonymous";
            this.avatarStream.image.onload = (image) => {
                let canvas = Object.assign(document.createElement("canvas"), {width, height});
                // let canvas = document.getElementById('canvasWeboji');
                // canvas.width = this.avatarStream.image.width;
                // canvas.height = this.avatarStream.image.height;
                // canvas.width = 500;
                // canvas.height = 500;
                // document.getElementById("vtail").appendChild(canvas);

                let context = canvas.getContext("2d");
                /*context.drawImage(this.avatarStream.image,
                    0, 0,
                    this.avatarStream.image.width, this.avatarStream.image.height,
                    0, 0,
                    500, 500
                );*/

                // console.info("toDataURL",canvas.toDataURL());

                this.avatarStream.context = canvas.getContext("2d");

                let stream = canvas.captureStream();
                this.localStream.avatarTrack = Object.assign(stream.getVideoTracks()[0], {enabled: true});
                this.startAvatarStream();



                resolve();
            };
            // this.avatarStream.image.src = imageSrc;
            // this.avatarStream.image.src = 'https://ipfs.io/ipfs/QmR36VFfo1hH2RAwVs4zVJ5btkopGip5cW7ydY4jUQBrKW';
            this.avatarStream.image.src = 'data:image/png;base64,'+AUTH_USER.avatar;
            // this.avatarStream.image.src = 'http://www.flexbricks.com/img/logo.png';

        });
    }

    startAvatarStream() {
        console.info("startAvatarStream");
        this.avatarStream.iterval = setInterval(() => {
            this.avatarStream.context.drawImage(this.avatarStream.image,
                0, 0,
                this.avatarStream.image.width, this.avatarStream.image.height,
                0, 0,
                500, 500
                );
            // console.info("DRAW", this.timestamp);
        }, 1000);
    }

    //--- PLAYER STATE METHODS: END ---//

    stopAvatarStream() {
        console.info("stopAvatarStream");
        if (this.avatarStream.iterval) {
            clearInterval(this.avatarStream.iterval);
        }

    }

    //--- PLAYER PRELOADER STATE: START ---//
    setPreloaderState(state, setFromHistory = false) {
        console.info("setPreloaderState", state, setFromHistory, this.preloaderState, this.preloaderStateHistory);
        return;
        state = PRELOADER_STATES.HIDDEN;
        if (this.preloaderState === state) return;
        if (setFromHistory) {
            state = this.preloaderStateHistory;
        } else if (this.preloaderState === PRELOADER_STATES.EXIT) {
            this.preloaderStateHistory = state;
            return;
        }

        if (state === PRELOADER_STATES.EXIT) {
            this.preloaderStateHistory = this.preloaderState;
        }
        this.preloaderState = state;

        this.playerWrapper.classList.remove(PRELOADER_WRAPPER);

        for (let prop in PRELOADER_STATES) {
            if (PRELOADER_STATES[prop] !== PRELOADER_STATES.HIDDEN) {
                this.playerWrapper.classList.remove(PRELOADER_STATES[prop]);
            }
        }

        if (state !== PRELOADER_STATES.HIDDEN) {
            this.playerWrapper.classList.add(PRELOADER_WRAPPER, state);
            if (this.productsList) this.productsList.hideProductsList();
            if (this.takeScreenshot) this.takeScreenshot.close();
            this.STATE = SESSION_STATES.NOT_LIVE;
        } else {
            this.STATE = SESSION_STATES.LIVE;
        }
        switch (state) {
            case PRELOADER_STATES.HIDDEN:
                this.STATE = SESSION_STATES.LIVE;
                break;
            case PRELOADER_STATES.LOADING:
                this.STATE = SESSION_STATES.LOADING;
                break;
            default:
                this.STATE = SESSION_STATES.NOT_LIVE;

        }

    }

    //--- PLAYER STATE METHODS: START ---//
    setPlayerOrientation(orientation) {
        this.playerOrientation = orientation;
        console.log(this);
        if (orientation === PLAYER_ORIENTATION.VERTICAL) {
            this.playerWrapper.classList.add(orientation);
            this.playerWrapper.classList.remove(PLAYER_ORIENTATION.HORIZONTAL);
        } else {
            this.playerWrapper.classList.add(orientation);
            this.playerWrapper.classList.remove(PLAYER_ORIENTATION.VERTICAL);
        }
    }

    //--- MINIPLAYER MODE METHODS: END ---//

    //--- MINIPLAYER MODE METHODS: START ---//
    setMiniplayerMode() {
        this.miniplayerOn = true;
        this.playerWrapper.classList.add(MINIPLAYER_WRAPPER);
    }

    unsetMiniplayerMode() {
        this.miniplayerOn = false;
        this.playerWrapper.classList.remove(MINIPLAYER_WRAPPER);
    }

    //--- VIDEO CONTROLS METHODS: START ---//
    setVideoControls() {
        this.showVideoControls = true;
        this.playerWrapper.classList.add(PLAYER_CONTROLS_WRAPPER);
    }

    //--- VIDEO CONTROLS METHODS: END ---//

    unsetVideoControls() {
        this.showVideoControls = false;
        this.playerWrapper.classList.remove(PLAYER_CONTROLS_WRAPPER);
    }

    //--- LIVE TOUR METHODS: START ---//
    setLiveTourState() {
        this.liveTour = true;
        this.playerWrapper.classList.add(PLAYER_LIVE_WRAPPER);
    }

    unsetLiveTourState() {
        this.liveTour = false;
        this.playerWrapper.classList.remove(PLAYER_LIVE_WRAPPER);
    }

    //--- LIVE TOUR METHODS: START ---//

    initControls() {
        /*if (this.productsList) {
            this.controls.productsListBtn = new ControlButton(this.playerWrapper.querySelector(".vtail-player-button_products-list"));
            this.controls.productsListBtn.unsetActive();
            this.controls.productsListBtn.show();
            this.controls.productsListBtn.on('click', (e) => {
                if (this.controls.productsListBtn.isActive) {
                    this.controls.productsListBtn.unsetActive();
                    this.productsList.hideProductsList();
                } else {
                    this.controls.productsListBtn.setActive();
                    this.productsList.showProductsList();
                }
            });
            if (!CONFIG.PRIVATE_PRODUCT_LIST) {
                this.controls.productsListBtn.hide();
            }

        }*/
        // this.controls.selfCameraOnBtn = new ControlButton(this.playerWrapper.querySelector(".vtail-player-button_camera.vtail-camera-turn-on"));
        this.controls.selfCameraOnBtn = new ControlButton(document.querySelector(".player__button.mask:not(.off)"));
        this.controls.selfCameraOnBtn.show();
        this.controls.selfCameraOnBtn.on("click", (e) => {
            this.turnOnSelfView();
        });

        this.controls.selfCameraOffBtn = new ControlButton(document.querySelector(".player__button.mask.off"));
        this.controls.selfCameraOffBtn.hide();
        this.controls.selfCameraOffBtn.on("click", (e) => {
            this.turnOffSelfView();
        });

        /*this.controls.screenShareOnBtn = new ControlButton(this.playerWrapper.querySelector(".vtail-player-button_share-screen.vtail-screen-share-on"));
        this.controls.screenShareOnBtn.hide();
        this.controls.screenShareOnBtn.on("click", (e) => {
            // this.turnOffScreenShare();
            this.turnOnScreenShare();
        });
        this.controls.screenShareOffBtn = new ControlButton(this.playerWrapper.querySelector(".vtail-player-button_share-screen.vtail-screen-share-off"));
        this.controls.screenShareOffBtn.hide();
        this.controls.screenShareOffBtn.on("click", (e) => {
            this.turnOffScreenShare();
        });*/


        this.controls.microphoneOnBtn = new ControlButton(document.querySelector(".player__button.microphone.off"));
        this.controls.microphoneOnBtn.hide();
        this.controls.microphoneOnBtn.on("click", (e) => {
            this.turnOnMicrophone();
        });


        this.controls.microphoneOffBtn = new ControlButton(document.querySelector(".player__button.microphone:not(.off)"));
        this.controls.microphoneOffBtn.show();
        this.controls.microphoneOffBtn.on("click", (e) => {
            this.turnOffMicrophone();
        });

        /*this.controls.askProductBtn = new ControlButton(this.playerWrapper.querySelector(".vtail-player-button_ask-product"));
        this.controls.askProductBtn.hide();
        this.controls.askProductBtn.on('click', (e) => {
            this.onAskButtonClick();
        });

        this.controls.miniPlayerBtn = new ControlButton(this.playerWrapper.querySelector(".vtail-player-button_miniplayer"));
        this.controls.miniPlayerBtn.on("click", (e) => {
            if (this.miniplayerOn) {
                this.restorePlayer();
            } else {
                this.minimizePlayer();
            }
        });
        this.controls.miniPlayerBtn.hide();

        this.controls.inviteFriendBtn = new ControlButton(this.playerWrapper.querySelector(".vtail-player-button_invite"));
        this.controls.inviteFriendBtn.hide();
        this.controls.inviteFriendBtn.on('click', (e) => {
            this.onInviteFriendClick();
        });*/

        this.controls.cancelCall = new ControlButton(document.querySelector(".player__button.cancel"));
        this.controls.cancelCall.show();
        this.controls.cancelCall.on("click", (e) => {
            this.turnOffCall();
        });

        /*if (this.IS_PUBLIC_BROADCST) {//USER IS GUEST (Public tour)
            this.chatLike = new ChatLikesClass();
            this.controls.microphoneOffBtn.hide();
            this.controls.microphoneOnBtn.hide();
            this.controls.selfCameraOffBtn.hide();
            this.controls.selfCameraOnBtn.hide();
            this.controls.screenShareOffBtn.hide();
            this.controls.screenShareOnBtn.hide();
            this.controls.askProductBtn.hide();
            this.controls.inviteFriendBtn.hide();
            this.controls.cancelCall.hide();

            document.querySelector(".vtail-player-time").classList.add("vtail-hidden");
        }*/
    }

    /******************************************************************************************************************/
    /*initProductsList() {
        this.productsList = new ProductsListClass({
            vtailWrapper: this.playerWrapper,
            playerVideoWrapper: this.playerVideoWrapper,
            controlsButtonsWrapper: this.controlsButtonsWrapper,
            // onboarding: this.onboarding,
        });

        this.productsList.on("onSetCurrentProduct", e => {
            console.info("onSetCurrentProduct", e);
        });
        this.productsList.on("onProductClick", product => {
            console.info("onProductClick", product);
            this.emit("productDetail", product);
            this.minimizePlayer();

        });
        /!*this.productsList.on("onHideProductsList", e => {
            console.info("onHideProductsList", e);
            if (this.controls.productsListBtn) {
                this.controls.productsListBtn.unsetActive();
            }
        });*!/
    }*/

    initAlerts() {
        this.vtailAlert = document.createElement('div');
        this.vtailAlert.classList.add("vtail-alert", "vtail-hidden");
        this.playerVideoWrapper.append(this.vtailAlert);
    }

    showAlert(message, autoHide = false) {
        this.vtailAlert.innerText = message;
        this.vtailAlert.classList.remove('vtail-hidden');
        if (autoHide) {
            setTimeout(() => {
                this.hideAlert();
            }, 3000);
        }
    }

    hideAlert() {
        this.vtailAlert.classList.add('vtail-hidden');
        this.vtailAlert.innerText = '';
    }

    /******************************************************************************************************************/

    onOffer(data) {
        console.info("onOffer", data);
        let user = this.onlineUsers.find(item => item.session_id == data.sender_session_id);
        if (user) {
            this.createConnection(user,
                {
                    sdp: data.sdp,
                    type: data.type,
                    sender_session_id: user.session_id
                }
            );
        }
    }


    onOnlineUsers(data) {
        console.info("onOnlineUsers", this.timestamp, data.users, this.broadcast);
        this.onlineUsers = data.users;
        // if (this.onlineUsers.length <=1){
        this.createConnectionsForOnlineUsers();
        this.emit("broadcastOnlineUsers", this.onlineUsers);
        // }
    }

    onOnlineUser(user) {
        console.info("onOnlineUser", user);
        this.onlineUsers.push(user);//FIXME need merge (or delete existing with same ID
        console.info("onOnlineUser", this.onlineUsers);
    }

    onOfflineUser(user) {
        console.info("onOfflineUser", user);
        this.onlineUsers = this.onlineUsers.filter(item => item.session_id !== user.session_id);
        if (!this.broadcast) return;
        if (this.connections[user.session_id]) {
            this.connections[user.session_id].close();
            this.connections[user.session_id] = null;
        }
        if (this.audioElemnts[user.session_id]) {
            this.audioElemnts[user.session_id].parentNode.removeChild(this.audioElemnts[user.session_id]);
            this.audioElemnts = this.audioElemnts.filter(element => element !== this.audioElemnts[user.session_id])
        }
        this.videoStreamer.classList.add('vtail-hidden');
        //TODO set player state to Waiting
        this.emit("broadcastOfflineUser", user);
        //TODO set LOADING if no connections

        if (this.onlineUsers.length === 0) {
            this.setPreloaderState(PRELOADER_STATES.LOADING);
        }
    }

    onBroadcastFinish(broadcastID) {
        console.info("onBroadcastFinish", broadcastID);
        this.setPreloaderState(PRELOADER_STATES.ISOVER);
        this.finishBroadcast();

    }

    onWSSclose() {
        console.info("onWSSclose");
        this.setPreloaderState(PRELOADER_STATES.DISCONNECT);
        Object.keys(this.connections).forEach(key => {
            if (this.connections[key]) {
                this.connections[key].close();
                this.connections[key] = null;
            }
        });
        /*if (this.connections['consultant']){
            this.connections['consultant'].close();
            this.connections['consultant'] = null;
        }*/
        // this.finishBroadcast(false);
    }

    onWSSreconnect() {
        console.info("onWSSreconnect");
        this.setPreloaderState(PRELOADER_STATES.LOADING);
        // this.emit("onWSSreconnect");
    }

    finishBroadcast(sendFinish = false) {
        console.info("finishBroadcast", sendFinish);
        return new Promise((resolve, reject) => {
            this.videoClient.removeAttribute("src");
            this.videoClient.removeAttribute("srcObject");
            this.videoClient.classList.add("vtail-hidden");

            if (this.countDownElement) {
                this.countDownElement.textContent = '';
            }

            this.stopAvatarStream();
            clearInterval(this.countDownInterval);
            clearTimeout(this.getBroadcastTimout);
            clearTimeout(this.getClientStateTimout);

            if (this.localStream && this.localStream.stream) {
                this.localStream.stream.getTracks().forEach(track => track.stop());
            }

            Object.keys(this.connections).forEach(key => {
                if (this.connections[key]) {
                    this.connections[key].close();
                    this.connections[key] = null;
                }
            });
            /*this.connections.forEach(connection => {
                connection.close();
                connection = null;
            });*/
            this.connections = [];

            this.audioElemnts.forEach(audioElement => {
                audioElement.parentNode.removeChild(audioElement);
            });
            this.audioElemnts = [];

            /*if (this.connections['consultant']){
                this.connections['consultant'].close();
                this.connections['consultant'] = null;
            }*/

            this.emit("sessionEnd");

            let state = AUTH_USER.state;
            auth.updateUser({state: CLIENT_STATES.NEW});
            let broadcastID = this.broadcast ? this.broadcast.id : null;
            this.broadcast = null;
            sendFinish = false;
            if (sendFinish) {
                console.info("sendFinish", this.broadcast);
                let deleteRequest;
                // if (this.broadcast) {
                if (broadcastID) {
                    deleteRequest = api.broadcastDeleteMyPrivateBroadcast(broadcastID);
                } else if (state === CLIENT_STATES.REQUEST_ASAP) {
                    deleteRequest = api.broadcastDeleteMyPrivateBroadcastRequest();
                } else {
                    resolve();
                }
                deleteRequest
                    .then(data => {
                        console.info("deleteRequest", data);
                        this.emit("privateSessionFinished");
                        resolve();
                    })
                    .catch(error => {
                        console.error("broadcastDeleteMyPrivateBroadcast Error", error);
                        reject();
                    })
            } else {
                resolve();
            }
        });
    }

    createConnectionsForOnlineUsers() {
        console.info("createConnectionsForOnlineUsers", this.onlineUsers);
        this.onlineUsers.forEach(user => {
            console.info("createConnectionsForOnlineUsers user", user);
            console.info("createConnectionsForOnlineUsers this.broadcast", this.broadcast);
            this.createConnection(user);
        })
    }

    createStream(){
        console.info("createStream");
        /*let canvasElt = document.getElementById('canvasWeboji');
        let stream = canvasElt.captureStream(25);
        this.localStream.avatarTrack = Object.assign(stream.getVideoTracks()[0], {enabled: true});*/

        this.selfViewStream.addTrack(this.localStream.avatarTrack);

        console.info("this.selfViewStream", this.selfViewStream);
        console.info("this.selfViewStream", this.videoClient);

        this.videoClient.classList.remove('vtail-hidden');
        this.videoClient.srcObject = this.selfViewStream;
        this.videoClient.muted = true;

        /*let audioCtx = new AudioContext();
        let source = audioCtx.createMediaStreamSource(this.localStream.stream);

        let biquadFilter = audioCtx.createBiquadFilter();
        biquadFilter.frequency.value = 1500;
        biquadFilter.type = 'bandpass';
        biquadFilter.Q.value = 30.0;*/

        /*// biquadFilter.type = "lowshelf";
        biquadFilter.frequency.value = 99999;
        biquadFilter.gain.value = 1;*/


       /* biquadFilter.connect(audioCtx.destination);
        source.connect(biquadFilter);*/

        /*let audioCtx = new (window.AudioContext || window.webkitAudioContext)();

        let analyser = audioCtx.createAnalyser();
        analyser.minDecibels = -90;
        analyser.maxDecibels = -10;
        analyser.smoothingTimeConstant = 0.85;

        let distortion = audioCtx.createWaveShaper();
        let gainNode = audioCtx.createGain();
        let biquadFilter = audioCtx.createBiquadFilter();
        let convolver = audioCtx.createConvolver();

        let source = audioCtx.createMediaStreamSource(this.localStream.stream);

        source.connect(distortion);
        distortion.connect(biquadFilter);
        biquadFilter.connect(gainNode);
        convolver.connect(gainNode);
        gainNode.connect(analyser);
        analyser.connect(audioCtx.destination);

        let peer = audioCtx.createMediaStreamDestination();
        source.connect(biquadFilter);
        biquadFilter.connect(peer);

        console.info("peer", peer);
        let stremDest = peer.stream;
        console.info("stremDest", stremDest);
        let audioTracks = stremDest.getAudioTracks();
        this.localStream.voiceTrack = audioTracks[0];
        console.info("stremDest getAudioTracks", stremDest.getAudioTracks());*/
    }

    /*changeVoice(mediaStream){

        let audioContext = new AudioContext();
        let audioSources = audioContext.createMediaStreamSource(mediaStream);
        /!*let context = new AudioContext();
        var microphone = context.createMediaStreamSource(mediaStream);
        var filter = context.createBiquadFilter();
        filter.type = "lowshelf";
        filter.frequency.setTargetAtTime(1000, context.currentTime, 0);
        filter.gain.setTargetAtTime(25, context.currentTime, 0);
        var peer = context.createMediaStreamDestination();
        microphone.connect(filter);
        filter.connect(peer);


        console.info("AC peer.stream", peer.stream);
        peer.stream.getTracks().forEach(track => {
            console.info("AC peer.stream track", track);
        });

        let audioTracksPeer = peer.stream.getAudioTracks();
        this.localStream.audioTrackContext = audioTracksPeer[0];*!/

        var
            // audioContext,
            // audioSources = [],
            pitchShifterProcessor
            // spectrumAudioAnalyser,
            // sonogramAudioAnalyser
            // canvas,
            // canvasContext,
            // barGradient,
            // waveGradient
        ;

        var
            // audioSourcesNames = ['MP3 file', 'Microphone'],
            // audioSourceIndex = 0,
            // audioVisualisationNames = ['Spectrum', 'Wave', 'Sonogram'],
            // audioVisualisationIndex = 0,
            validGranSizes = [256, 512, 1024, 2048, 4096, 8192],
            grainSize = validGranSizes[1],
            // pitchRatio = 1.0,
            pitchRatio = 0.75,
            overlapRatio = 1.50
        ;

        let hannWindow = function (length) {

            var w = new Float32Array(length);
            for (var i = 0; i < length; i++) {
                w[i] = 0.5 * (1 - Math.cos(2 * Math.PI * i / (length - 1)));
            }
            return w;
        };

        let linearInterpolation = function (a, b, t) {
            return a + (b - a) * t;
        };

        if (pitchShifterProcessor) {
            pitchShifterProcessor.disconnect();
        }

        if (audioContext.createScriptProcessor) {
            pitchShifterProcessor = audioContext.createScriptProcessor(grainSize, 1, 1);
        } else if (audioContext.createJavaScriptNode) {
            pitchShifterProcessor = audioContext.createJavaScriptNode(grainSize, 1, 1);
        }

        pitchShifterProcessor.buffer = new Float32Array(grainSize * 2);
        pitchShifterProcessor.grainWindow = hannWindow(grainSize);
        pitchShifterProcessor.onaudioprocess = function (event) {
            // console.info("onaudioprocess", event);
            var inputData = event.inputBuffer.getChannelData(0);
            var outputData = event.outputBuffer.getChannelData(0);

            for (i = 0; i < inputData.length; i++) {

                // Apply the window to the input buffer
                inputData[i] *= this.grainWindow[i];

                // Shift half of the buffer
                this.buffer[i] = this.buffer[i + grainSize];

                // Empty the buffer tail
                this.buffer[i + grainSize] = 0.0;
            }

            // Calculate the pitch shifted grain re-sampling and looping the input
            var grainData = new Float32Array(grainSize * 2);
            for (var i = 0, j = 0.0;
                 i < grainSize;
                 i++, j += pitchRatio) {

                var index = Math.floor(j) % grainSize;
                var a = inputData[index];
                var b = inputData[(index + 1) % grainSize];
                grainData[i] += linearInterpolation(a, b, j % 1.0) * this.grainWindow[i];
            }

            // Copy the grain multiple times overlapping it
            for (i = 0; i < grainSize; i += Math.round(grainSize * (1 - overlapRatio))) {
                for (j = 0; j <= grainSize; j++) {
                    this.buffer[i + j] += grainData[j];
                }
            }

            // Output the first half of the buffer
            for (i = 0; i < grainSize; i++) {
                outputData[i] = this.buffer[i];
            }
        };

        // pitchShifterProcessor.connect(spectrumAudioAnalyser);
        // pitchShifterProcessor.connect(sonogramAudioAnalyser);
        var peer = audioContext.createMediaStreamDestination();
        pitchShifterProcessor.connect(peer);



        audioSources.connect(pitchShifterProcessor);

        // var peer = audioContext.createMediaStreamDestination();
        peer.stream.getTracks().forEach(track => {
            console.info("AC peer.stream track", track);
        });

        let audioTracksPeer = peer.stream.getAudioTracks();
        this.localStream.audioTrack = audioTracksPeer[0];



        // this.streamDestination = peer.stream;
    }*/
    /*changeVoice(mediaStream){

        let audioContext = new AudioContext();
        let audioSources = audioContext.createMediaStreamSource(mediaStream);
        let context = new AudioContext();
        var microphone = context.createMediaStreamSource(mediaStream);
        var filter = context.createBiquadFilter();
        filter.type = "lowshelf";
        // filter.frequency.setTargetAtTime(1000, context.currentTime, 0);
        // filter.gain.setTargetAtTime(25, context.currentTime, 0);
        var peer = context.createMediaStreamDestination();
        microphone.connect(filter);
        filter.connect(peer);


        console.info("AC peer.stream", peer.stream);
        peer.stream.getTracks().forEach(track => {
            console.info("AC peer.stream track", track);
        });

        let audioTracksPeer = peer.stream.getAudioTracks();
        this.localStream.audioTrack = audioTracksPeer[0];
    }*/
    changeVoice(mediaStream){
        // return new Promise((resolve, reject) => {



        let audioContext = new AudioContext();
        let audioSources = audioContext.createMediaStreamSource(mediaStream);

            // let audioContext = new OfflineAudioContext(audioBuffer.numberOfChannels, audioBuffer.length, audioBuffer.sampleRate);

        // Source
        // let audioSources = ctx.createBufferSource();
        // audioSources.buffer = audioBuffer;

            // Wobble
            let oscillator1 = audioContext.createOscillator();
            oscillator1.frequency.value = -10;
            oscillator1.type = 'sawtooth';

            let oscillator2 = audioContext.createOscillator();
            oscillator2.frequency.value = 50;
            oscillator2.type = 'sawtooth';

            let oscillator3 = audioContext.createOscillator();
            oscillator3.frequency.value = 30;
            oscillator3.type = 'sawtooth';
            // ---
            let oscillatorGain = audioContext.createGain();
            oscillatorGain.gain.value = 0.007;
            // ---
            let oscillatorGain2 = audioContext.createGain();
            oscillatorGain2.gain.value = 0.007;
            // ---
            let delay = audioContext.createDelay();
            delay.delayTime.value = 0.01;
            // ---
            let delay2 = audioContext.createDelay();
            delay2.delayTime.value = 0.01;

            let filter = audioContext.createBiquadFilter();
            filter.type = "lowpass";
            filter.frequency.value = 2000;

            let compressor = audioContext.createDynamicsCompressor();
            let compressor2 = audioContext.createDynamicsCompressor();
            let compressor3 = audioContext.createDynamicsCompressor();
            let compressor4 = audioContext.createDynamicsCompressor();
            let compressor5 = audioContext.createDynamicsCompressor();

            // Create graph
            oscillator1.connect(oscillatorGain);
            oscillator2.connect(oscillatorGain);
            // oscillator3.connect(oscillatorGain);
            oscillatorGain.connect(delay.delayTime);
            // ---
            audioSources.connect(compressor2)
            compressor2.connect(delay);
            delay.connect(compressor3)
            compressor3.connect(filter);
            filter.connect(compressor5)


            oscillator3.connect(oscillatorGain2);
            oscillatorGain2.connect(delay2.delayTime);

            audioSources.connect(compressor)
            compressor.connect(delay2);
            delay2.connect(compressor4)
            compressor4.connect(filter)
            filter.connect(compressor5);

            // compressor5.connect(audioContext.destination);

            var peer = audioContext.createMediaStreamDestination();
        compressor5.connect(peer);


        console.info("AC peer.stream", peer.stream);
        peer.stream.getTracks().forEach(track => {
            console.info("AC peer.stream track", track);
        });

        let audioTracksPeer = peer.stream.getAudioTracks();
        this.localStream.audioTrack = audioTracksPeer[0];
            //
            //filter.connect(ctx.destination);
            //compressor.connect(ctx.destination);

            // source.connect(delay);
            // delay.connect(filter);
            // filter.connect(ctx.destination);

            // Render
            oscillator1.start(0);
            oscillator2.start(0);
            oscillator3.start(0);
            // audioSources.start(0);
            // fire.start(0);
            // let outputAudioBuffer = await audioContext.startRendering();
            // return outputAudioBuffer;
    }

    createConnection(user, offer) {
        console.info("createConnection", user, offer);
        console.info("createConnection this.broadcast", this.timestamp, this.broadcast);
        document.querySelector('.sidekey-guid__partner').innerHTML = user.user_name;
        // if (user.user_type != "client") return;
        this.connections[user.session_id] = new BroadcastConnection({
            // this.connections[user.ses_id] = new BroadcastConnection({
            // localStream: new MediaStream([this.localStream.audioTrackAnonym, this.localStream.avatarTrack]),
            localStream: new MediaStream([this.localStream.audioTrackAnonym, this.localStream.avatarTrack]),
            // localStream: this.localStream.stream,
            // localStream: this.streamDestination,
            receiverSessionID: user.session_id,
            user_role: user.user_type,
            broadcastID: this.broadcast.id,
            mediaElement: this.getMediaElementForConnection(user),
            // videoStreamer: this.videoStreamer,
            // videoClient: this.videoClient,
            // videoStreamer: document.getElementById("vtail-player-video-local"),
            // videoClient: document.getElementById("vtail-player-video-remote"),
            configuration: {
                iceServers: /*[this.broadcast.config.turns],*/
                    [
                        {
                            urls: this.broadcast.config.turns.urls,
                            username: this.broadcast.config.turns.username,
                            credential: this.broadcast.config.turns.credentials
                        }
                    ]
                // iceServers:[{urls:"stun:stun.l.google.com:19302"}]
            },
            offer: offer || null
        });
        this.connections[user.session_id].on("ontrack", (e) => {
            console.info("createConnection ontrack", e);
            if (user.user_type === USER_TYPES.CONSULTANT) {
                // this.startCountDown();
            }
            this.videoStreamer.classList.remove('vtail-hidden');
            this.setPreloaderState(PRELOADER_STATES.HIDDEN);
            this.emit("sessionStart", user.id);
        });

        console.info("this.connections", this.connections);
    }

    getMediaElementForConnection(user) {
        console.info("getMediaElementForConnection", user.user_name, user.user_type);
        return this.videoStreamer;
        switch (user.user_type) {
            case USER_TYPES.CONSULTANT:
                console.info("videoStreamer", this.videoStreamer);
                return this.videoStreamer;
            case USER_TYPES.CLIENT:
                console.info("videoClient", this.videoClient);
                return this.videoClient;
            /*case USER_TYPES.FRIEND:
                let audioElemnt = document.createElement('audio');
                audioElemnt.setAttribute('id', user.session_id);
                //audioDOM.setAttribute('controls', true);
                audioElemnt.autoplay = true;
                audioElemnt.playsinline = true;
                this.playerWrapper.appendChild(audioElemnt);
                this.audioElemnts[user.session_id] = audioElemnt;
                return this.audioElemnts[user.session_id];*/
            default:
                console.error("ERROR!");
        }
    }

    enumerateDevices() {
        console.info("enumerateDevices");
        if (!(navigator.mediaDevices && navigator.mediaDevices.enumerateDevices)) return Promise.reject('mediaDevices');
        return navigator.mediaDevices.enumerateDevices()
            .then(devices => {

                let supportedConstraints = navigator.mediaDevices.getSupportedConstraints();
                console.info(supportedConstraints);
                devices.forEach(device => {
                    console.info("device", device.kind, device.deviceId)
                    if (device.kind === 'audioinput') {
                        this.mediaDevices.audio.push(device);
                        this.constraints.audio = true;
                    }
                    // this.constraints.video = false;
                    if (device.kind === 'videoinput') {
                        this.mediaDevices.video.push(device);
                        // this.constraints.video = true
                        this.constraints.video = { //TODO check device orientation
                            // width: {min: 240, max: 426}, //FIXME need support most of devices
                            // width: {min: 480, max: 640}, //FIXME need support most of devices
                            // width: {min: 240, max: 240}, //FIXME need support most of devices
                            // height: {min: 240, max: 240}, //FIXME need support most of devices
                            // height: {min: 480, max: 640}, //FIXME need support most of devices
                            // height: {min: 240, max: 426}, //FIXME need support most of devices
                            // aspectRatio: 0.5625,
                            facingMode: "user",
                            // facingMode : "environment"
                            // aspectRatio: 1.777777778
                        }
                    }
                });
                console.info("this.constraints", this.constraints);
                /*if (!this.constraints.video) {
                    alert("camera not found");
                }*/
                if (!this.constraints.audio) {
                    // alert("mic not found");
                    return false;
                }
                /*// console.info("this.constraints", this.constraints);
                return navigator.mediaDevices.getUserMedia(this.constraints)
                    .then(mediaStream=> {
                        return mediaStream;
                    })*/
            })
    }

    getUserMedia() {
        console.info("getUserMedia");
        return navigator.mediaDevices.getUserMedia(this.constraints)
            .then(mediaStream => {
                let audioTracks = mediaStream.getAudioTracks();
                let videoTracks = mediaStream.getVideoTracks();

                // this.changeVoice(mediaStream);

                /**********************************************************************/
                /*
                let audioContext = new AudioContext();
                let audioSources = audioContext.createMediaStreamSource(mediaStream);

                // let audioContext = new OfflineAudioContext(audioBuffer.numberOfChannels, audioBuffer.length, audioBuffer.sampleRate);

                // Source
                // let audioSources = ctx.createBufferSource();
                // audioSources.buffer = audioBuffer;

                // Wobble
                let oscillator1 = audioContext.createOscillator();
                oscillator1.frequency.value = -10;
                oscillator1.type = 'sawtooth';

                let oscillator2 = audioContext.createOscillator();
                oscillator2.frequency.value = 50;
                oscillator2.type = 'sawtooth';

                let oscillator3 = audioContext.createOscillator();
                oscillator3.frequency.value = 30;
                oscillator3.type = 'sawtooth';
                // ---
                let oscillatorGain = audioContext.createGain();
                oscillatorGain.gain.value = 0.007;
                // ---
                let oscillatorGain2 = audioContext.createGain();
                oscillatorGain2.gain.value = 0.007;
                // ---
                let delay = audioContext.createDelay();
                delay.delayTime.value = 0.01;
                // ---
                let delay2 = audioContext.createDelay();
                delay2.delayTime.value = 0.01;

                let filter = audioContext.createBiquadFilter();
                filter.type = "lowpass";
                filter.frequency.value = 2000;

                let compressor = audioContext.createDynamicsCompressor();
                let compressor2 = audioContext.createDynamicsCompressor();
                let compressor3 = audioContext.createDynamicsCompressor();
                let compressor4 = audioContext.createDynamicsCompressor();
                let compressor5 = audioContext.createDynamicsCompressor();

                // Create graph
                oscillator1.connect(oscillatorGain);
                oscillator2.connect(oscillatorGain);
                // oscillator3.connect(oscillatorGain);
                oscillatorGain.connect(delay.delayTime);
                // ---
                audioSources.connect(compressor2)
                compressor2.connect(delay);
                delay.connect(compressor3)
                compressor3.connect(filter);
                filter.connect(compressor5)


                oscillator3.connect(oscillatorGain2);
                oscillatorGain2.connect(delay2.delayTime);

                audioSources.connect(compressor)
                compressor.connect(delay2);
                delay2.connect(compressor4)
                compressor4.connect(filter)
                filter.connect(compressor5);

                // compressor5.connect(audioContext.destination);

                var peer = audioContext.createMediaStreamDestination();
                compressor5.connect(peer);


                console.info("AC peer.stream", peer.stream);
                peer.stream.getTracks().forEach(track => {
                    console.info("AC peer.stream track", track);
                });

                let audioTracksPeer = peer.stream.getAudioTracks();
                this.localStream.audioTrack = audioTracksPeer[0];
                //
                //filter.connect(ctx.destination);
                //compressor.connect(ctx.destination);

                // source.connect(delay);
                // delay.connect(filter);
                // filter.connect(ctx.destination);

                // Render
                oscillator1.start(0);
                oscillator2.start(0);
                oscillator3.start(0);
                // audioSources.start(0);
                // fire.start(0);
                // let outputAudioBuffer = await audioContext.startRendering();
                // return outputAudioBuffer;
                */
                /**********************************************************************/
                /*
                var audioContext = new AudioContext();

                let audioSources = audioContext.createMediaStreamSource(mediaStream);

                // let filter = audioContext.createBiquadFilter();
                // filter.type = "lowpass";
                // filter.frequency.value = 10000;

                let biquadFilter = audioContext.createBiquadFilter();
                // biquadFilter.frequency.value = 1500;
                // biquadFilter.type = 'bandpass';
                // biquadFilter.Q.value = 30.0;

                biquadFilter.type = "lowshelf";
                biquadFilter.frequency.setValueAtTime(1000, audioContext.currentTime);
                biquadFilter.gain.setValueAtTime(25, audioContext.currentTime);

                /!*!// biquadFilter.type = "lowshelf";
                biquadFilter.frequency.value = 99999;
                biquadFilter.gain.value = 1;*!/

                var peer = audioContext.createMediaStreamDestination();
                // filter.connect(peer);
                biquadFilter.connect(peer);

                audioSources.connect(biquadFilter)
                // audioSources.connect(biquadFilter)

                console.info("AC peer.stream", peer.stream);
                peer.stream.getTracks().forEach(track => {
                    console.info("AC peer.stream track", track);
                });

                let audioTracksPeer = peer.stream.getAudioTracks();
                this.localStream.audioTrack = audioTracksPeer[0];
*/

                /**********************************************************************/
                /**********************************************************************/
                var audioContext = new AudioContext();

                let audioSources = audioContext.createMediaStreamSource(mediaStream);

                let pitchChangeEffect = new Jungle( audioContext );

                let compressor = audioContext.createDynamicsCompressor();
                //let filter = ctx.createBiquadFilter();
                //filter.type = "lowpass";
                //filter.frequency.value = 10000;

                audioSources.connect(pitchChangeEffect.input)
                pitchChangeEffect.output.connect(compressor)
                pitchChangeEffect.setPitchOffset(.3);

                // compressor.connect(ctx.destination);
                //filter.connect(ctx.destination);

                // source.start(0);

                var peer = audioContext.createMediaStreamDestination();
                compressor.connect(peer);

                console.info("AC peer.stream", peer.stream);
                peer.stream.getTracks().forEach(track => {
                    console.info("AC peer.stream track", track);
                });

                let audioTracksPeer = peer.stream.getAudioTracks();
                this.localStream.audioTrackAnonym = audioTracksPeer[0];
                this.localStream.audioTrackOrigin = audioTracks[0];


                /**********************************************************************/

                /*let audioContext = new AudioContext();
                let audioSources = audioContext.createMediaStreamSource(mediaStream);
                /!*let context = new AudioContext();
                var microphone = context.createMediaStreamSource(mediaStream);
                var filter = context.createBiquadFilter();
                filter.type = "lowshelf";
                filter.frequency.setTargetAtTime(1000, context.currentTime, 0);
                filter.gain.setTargetAtTime(25, context.currentTime, 0);
                var peer = context.createMediaStreamDestination();
                microphone.connect(filter);
                filter.connect(peer);


                console.info("AC peer.stream", peer.stream);
                peer.stream.getTracks().forEach(track => {
                    console.info("AC peer.stream track", track);
                });

                let audioTracksPeer = peer.stream.getAudioTracks();
                this.localStream.audioTrackContext = audioTracksPeer[0];*!/
                var
                    // audioContext,
                    // audioSources = [],
                    pitchShifterProcessor
                    // spectrumAudioAnalyser,
                    // sonogramAudioAnalyser
                    // canvas,
                    // canvasContext,
                    // barGradient,
                    // waveGradient
                ;

                var
                    // audioSourcesNames = ['MP3 file', 'Microphone'],
                    // audioSourceIndex = 0,
                    // audioVisualisationNames = ['Spectrum', 'Wave', 'Sonogram'],
                    // audioVisualisationIndex = 0,
                    validGranSizes = [256, 512, 1024, 2048, 4096, 8192],
                    grainSize = validGranSizes[1],
                    // pitchRatio = 1.0,
                    pitchRatio = 0.75,
                    overlapRatio = 1.50
                ;

                let hannWindow = function (length) {

                    var w = new Float32Array(length);
                    for (var i = 0; i < length; i++) {
                        w[i] = 0.5 * (1 - Math.cos(2 * Math.PI * i / (length - 1)));
                    }
                    return w;
                };

                let linearInterpolation = function (a, b, t) {
                    return a + (b - a) * t;
                };

                if (pitchShifterProcessor) {
                    pitchShifterProcessor.disconnect();
                }

                if (audioContext.createScriptProcessor) {
                    pitchShifterProcessor = audioContext.createScriptProcessor(grainSize, 1, 1);
                } else if (audioContext.createJavaScriptNode) {
                    pitchShifterProcessor = audioContext.createJavaScriptNode(grainSize, 1, 1);
                }

                pitchShifterProcessor.buffer = new Float32Array(grainSize * 2);
                pitchShifterProcessor.grainWindow = hannWindow(grainSize);
                pitchShifterProcessor.onaudioprocess = function (event) {
                    // console.info("onaudioprocess", event);
                    var inputData = event.inputBuffer.getChannelData(0);
                    var outputData = event.outputBuffer.getChannelData(0);

                    for (i = 0; i < inputData.length; i++) {

                        // Apply the window to the input buffer
                        inputData[i] *= this.grainWindow[i];

                        // Shift half of the buffer
                        this.buffer[i] = this.buffer[i + grainSize];

                        // Empty the buffer tail
                        this.buffer[i + grainSize] = 0.0;
                    }

                    // Calculate the pitch shifted grain re-sampling and looping the input
                    var grainData = new Float32Array(grainSize * 2);
                    for (var i = 0, j = 0.0;
                         i < grainSize;
                         i++, j += pitchRatio) {

                        var index = Math.floor(j) % grainSize;
                        var a = inputData[index];
                        var b = inputData[(index + 1) % grainSize];
                        grainData[i] += linearInterpolation(a, b, j % 1.0) * this.grainWindow[i];
                    }

                    // Copy the grain multiple times overlapping it
                    for (i = 0; i < grainSize; i += Math.round(grainSize * (1 - overlapRatio))) {
                        for (j = 0; j <= grainSize; j++) {
                            this.buffer[i + j] += grainData[j];
                        }
                    }

                    // Output the first half of the buffer
                    for (i = 0; i < grainSize; i++) {
                        outputData[i] = this.buffer[i];
                    }
                };

                // pitchShifterProcessor.connect(spectrumAudioAnalyser);
                // pitchShifterProcessor.connect(sonogramAudioAnalyser);
                var peer = audioContext.createMediaStreamDestination();
                pitchShifterProcessor.connect(peer);



                audioSources.connect(pitchShifterProcessor);

                // var peer = audioContext.createMediaStreamDestination();
                peer.stream.getTracks().forEach(track => {
                    console.info("AC peer.stream track", track);
                });

                let audioTracksPeer = peer.stream.getAudioTracks();
                this.localStream.audioTrack = audioTracksPeer[0];

*/
                if (audioTracks.length) {//FIXME buttons
                    // this.localStream.audioTrack = audioTracks[0];
                    if (this.IS_PUBLIC_BROADCST) {
                        this.localStream.audioTrack.enabled = false;
                    }
                    // this.vtailContainer.classList.toggle("vtail-mic-on", !!this.localStream.audioTrack);

                }
                if (videoTracks.length) {
                    this.localStream.videoTrack = videoTracks[0];
                    // this.controls.selfCameraBtn.show();
                }
                this.localStream.stream = mediaStream;
                return this.localStream.stream;
            })
    }

    replaceSelfViewTrack(newTrack){
        console.info("replaceSelfViewTrack")
        this.selfViewStream.removeTrack(this.selfViewStream.getVideoTracks()[0]);
        
        this.selfViewStream.addTrack(newTrack);
    }

    turnOnSelfView() {
        console.info("turnOnSelfView", this.constraints.video);
        this.useSelfCamera = true;
        console.info(this.connections);
        if (!this.activeTracks.screen) {
            this.connectionsReplaceTrack(this.localStream.videoTrack);
        }
        //TODO FIXME
        this.connectionsReplaceTrack(this.localStream.audioTrackOrigin);


        this.replaceSelfViewTrack(this.localStream.videoTrack);
        this.activeTracks.camera = true;
        this.activeTracks.avatar = false;
        // window.JEELIZFACEEXPRESSIONS.switch_sleep(true);
        this.videoSelfview.classList.add('vtail-hidden');
        /*this.connections.forEach(connection=>{
            if (connection){
                connection.replaceTrack(this.localStream.videoTrack);
            }
        })*/

        // this.connections['consultant'].replaceTrack(this.localStream.videoTrack);
        /*this.videoClient.classList.remove('vtail-hidden');
        this.videoClient.srcObject = this.localStream.stream;
        this.videoClient.muted = true;*/
        this.videoClient.play();
        if (this.constraints.video) {
            this.controls.selfCameraOnBtn.hide();
            this.controls.selfCameraOffBtn.show();
        } else {
            // this.controls.selfCameraOffBtn.hide();
            // this.controls.selfCameraOnBtn.hide();
        }

    }

    turnOffSelfView() {
        console.info("turnOffSelfView", this.constraints.video);
        this.useSelfCamera = false;
        console.info(this.connections);

        if (!this.activeTracks.screen) {
            this.connectionsReplaceTrack(this.localStream.avatarTrack);
        }
        //TODO FIXME
        this.connectionsReplaceTrack(this.localStream.audioTrackAnonym);


        this.replaceSelfViewTrack(this.localStream.avatarTrack);
        this.activeTracks.camera = false;
        this.activeTracks.avatar = true;
        // window.JEELIZFACEEXPRESSIONS.switch_sleep(false);
        this.videoSelfview.classList.remove('vtail-hidden');
        /*this.connections.forEach(connection=>{
            console.info(connection);
            if (connection){
                connection.replaceTrack(this.localStream.avatarTrack);
            }
        })*/

        // if (this.connections['consultant']) {
        //     this.connections['consultant'].replaceTrack(this.localStream.avatarTrack);
        // }
        // this.videoClient.classList.add('vtail-hidden');
        // this.videoClient.removeAttribute("src");
        // this.videoClient.removeAttribute("srcObject");
        if (this.constraints.video) {
            this.controls.selfCameraOffBtn.hide();
            this.controls.selfCameraOnBtn.show();
        } else {
            // this.controls.selfCameraOffBtn.hide();
            // this.controls.selfCameraOnBtn.hide();
        }
    }

    turnOnMicrophone() {
        console.info("turnOnMicrophone");
        this.localStream.audioTrackAnonym.enabled = true;
        this.localStream.audioTrackOrigin.enabled = true;
        this.activeTracks.microphone = true;
        this.controls.microphoneOnBtn.hide();
        this.controls.microphoneOffBtn.show();
    }

    turnOffMicrophone() {
        console.info("turnOffMicrophone");
        this.localStream.audioTrackAnonym.enabled = false;
        this.localStream.audioTrackOrigin.enabled = false;
        this.activeTracks.microphone = false;
        this.controls.microphoneOffBtn.hide();
        this.controls.microphoneOnBtn.show();
    }

    turnOffCall() {
        this.close();
    }

    turnOnScreenShare() {
        console.info("turnOnScreenShare");
        navigator.mediaDevices.getDisplayMedia()
            .then(mediaStream => {
                console.info("getDisplayMedia", mediaStream);
                // let videoTracks = mediaStream.getVideoTracks();
                // console.info("videoTracks", videoTracks);
                this.localStream.screenShareTrack = mediaStream.getVideoTracks()[0];
                this.localStream.screenShareTrack.addEventListener('ended', () => {
                    console.log('screensharing has ended');
                    this.turnOffScreenShare()
                });
                this.connectionsReplaceTrack(this.localStream.screenShareTrack);
                this.activeTracks.screen = true;
            })
            .catch(error => {
                console.info("getDisplayMedia", error);
                this.controls.screenShareOnBtn.show();
                this.controls.screenShareOffBtn.hide();
            })
        this.controls.screenShareOnBtn.hide();
        this.controls.screenShareOffBtn.show();
    }

    turnOffScreenShare() {
        console.info("turnOffScreenShare");
        this.localStream.screenShareTrack.stop();
        this.activeTracks.screen = false;
        if (this.activeTracks.camera) {
            this.turnOnSelfView();
        } else {
            this.turnOffSelfView();
        }

        this.controls.screenShareOffBtn.hide();
        this.controls.screenShareOnBtn.show();
    }

    connectionsReplaceTrack(newTrack) {
        console.info("connectionsReplaceTrack", newTrack);
        Object.keys(this.connections).forEach(key => {
            if (this.connections[key]) {
                this.connections[key].replaceTrack(newTrack);
            }
        });
    }

    onAskButtonClick() {
        this.controls.askProductBtn.setActive();

        this.takeScreenshot.showRequestProduct(this.videoStreamer);
        // this.takeScreenshot.setProductState();
        // this.takeScreenshot.setFrame(this.videoStreamer);
    }

    onInviteFriendClick() {
        let inviteLink = INVITE_LINK + this.broadcast.id;
        navigator.clipboard.writeText(inviteLink)
            .then(data => {
                this.showAlert(translator.get("private_alert_share_link"), true);
            })
            .catch(e => {
                console.error("writeText", e)
            });
    }


    startCountDown() {
        if (this.countDownInterval) return;
        console.info("startCountDown", this.broadcast.config.broadcast_resolve_date);
        let targetTime = (this.broadcast && this.broadcast.config && this.broadcast.config.broadcast_resolve_date)
            ? this.broadcast.config.broadcast_resolve_date + DEFAULT_SESSION_DURATION
            : new Date().getTime() + DEFAULT_SESSION_DURATION;

        this.countDownElement = document.querySelector(".vtail-player-time");

        this.countDownInterval = setInterval(() => {

            // Get today's date and time
            let now = new Date().getTime();

            // Find the distance between now and the count down date
            let distance = Math.floor((targetTime - now) / 1000);
            distance = distance >= 0 ? distance : 0;
            // console.info("distance",distance);
            // Time calculations for days, hours, minutes and seconds
            // let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            // let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            // let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            // let seconds = Math.floor((distance % (1000 * 60)) / 1000);
            let days = this.formatTime(distance, 86400, 100);
            let hours = this.formatTime(distance, 3600, 24);
            let minutes = this.formatTime(distance, 60, 60);
            let seconds = this.formatTime(distance, 1, 60);

            // Display the result in the element with id="demo"
            this.countDownElement.textContent = hours + ":"
                + minutes + ":" + seconds;

            // If the count down is finished, write some text
            if (distance <= 0) {
                clearInterval(this.countDownInterval);
            }
        }, 1001);
    }

    formatTime(seconds, unit, modulo) {
        let str = ((Math.floor(seconds / unit)) % modulo).toString();
        if (str.length < 2) {
            str = "0" + str;
        }
        return str
    }

    minimizePlayer() {
        console.info("minimizePlayer");
        return;
        this.miniplayerOn = true;
        this.controls.miniPlayerBtn.element.title = translator.get('private_button_restore');
        if (this.productsList) this.productsList.hideProductsList();
        this.takeScreenshot.close();
        this.playerWrapper.classList.add("vtail-miniplayer");
        this.playerWrapper.style.top = null;
        this.playerWrapper.style.left = null;
        this.controls.overlayBtn.hide();
        this.emit("minimizePlayer");

        this.draggableStart();
    }

    restorePlayer() {
        console.info("restorePlayer");
        return;
        this.miniplayerOn = false;
        this.controls.miniPlayerBtn.element.title = translator.get('private_button_minimize');
        this.playerWrapper.classList.remove("vtail-miniplayer");
        this.playerWrapper.style.top = null;
        this.playerWrapper.style.left = null;
        this.controls.overlayBtn.show();
        this.emit("restorePlayer");
        this.draggableEnd();
    }

    sendProductRequest(product) {
        product.broadcast_id = this.broadcast.id;
        api.productsProductShowRequest(product)
            .then(data => {
                this.showAlert(translator.get("private_alert_product_request"), true);
            })
            .catch(error => {
                console.error("productsProductShowRequest", error)
            })
    }

    /*draggableStart() {
        console.info("draggableStart");
        this.draggableHeader.addEventListener('mousedown', this.draggableMouseDown, false);
        this.draggableHeader.addEventListener('touchstart', this.draggableMouseDown, false);
        this.draggablePosition.maxX = window.innerWidth - this.playerWrapper.offsetWidth;
        this.draggablePosition.maxY = window.innerHeight - this.playerWrapper.offsetHeight;
    }

    draggableEnd() {
        console.info("draggableEnd");
        this.draggableHeader.removeEventListener("mousedown", this.draggableMouseDown, false);
        this.draggableHeader.removeEventListener("touchstart", this.draggableMouseDown, false);

        window.removeEventListener('mousemove', this.draggableMouseMove, true);
        window.removeEventListener('touchmove', this.draggableMouseMove, true);

        window.removeEventListener('mouseup', this.draggableMouseUp, false);
        window.removeEventListener('touchend', this.draggableMouseUp, false);
    }

    draggableMouseDown(event) {
        this.draggablePosition.maxX = window.innerWidth - this.playerWrapper.offsetWidth;
        this.draggablePosition.maxY = window.innerHeight - this.playerWrapper.offsetHeight;
        this.draggablePosition.x = (event.type === "touchmove") ? event.touches[0].clientX : event.clientX;
        this.draggablePosition.y = (event.type === "touchmove") ? event.touches[0].clientY : event.clientY;

        console.info(this.draggablePosition);

        window.addEventListener('mousemove', this.draggableMouseMove, true);
        window.addEventListener('touchmove', this.draggableMouseMove, true);

        window.addEventListener('mouseup', this.draggableMouseUp, false);
        window.addEventListener('touchend', this.draggableMouseUp, false);
    }

    draggableMouseMove(event) {
        let clientX = (event.type === "touchmove") ? event.touches[0].clientX : event.clientX;
        let clientY = (event.type === "touchmove") ? event.touches[0].clientY : event.clientY;

        let left = this.playerWrapper.offsetLeft - this.draggablePosition.x + clientX;
        let top = this.playerWrapper.offsetTop - this.draggablePosition.y + clientY;

        this.draggablePosition.x = clientX;
        this.draggablePosition.y = clientY;

        left = left < 0 ? 0 : (left > this.draggablePosition.maxX ? this.draggablePosition.maxX : left);
        top = top < 0 ? 0 : (top > this.draggablePosition.maxY ? this.draggablePosition.maxY : top);

        this.playerWrapper.style.left = left + "px";
        this.playerWrapper.style.top = top + "px";
    }

    draggableMouseUp() {
        window.removeEventListener('mousemove', this.draggableMouseMove, true);
        window.removeEventListener('touchmove', this.draggableMouseMove, true);

        window.removeEventListener('mouseup', this.draggableMouseUp, false);
        window.removeEventListener('touchend', this.draggableMouseUp, false);
    }*/


    close() {
        console.info("close");
        this.emit("close");
        /*return new Promise((resolve, reject) => {
            this.finishBroadcast()
                .then(()=>{
                    this.removeListeners(this);
                    wss.off(WSS_CODES.OFFER, this.onOffer);
                    wss.off(WSS_CODES.ONLINE_USERS, this.onOnlineUsers);
                    wss.off(WSS_CODES.ONLINE_USER, this.onOnlineUser);
                    wss.off(WSS_CODES.OFFLINE_USER, this.onOfflineUser);
                    wss.off(WSS_CODES.BROADCAST_FINISH, this.onBroadcastFinish);
                    wss.off("onclose", this.onWSSclose);
                    wss.off("onreconncet", this.onWSSreconnect);
                    resolve();
                })
            .catch(error=>reject(error));
        });*/
    }

    removeListeners(element) {
        element.eventNames && element.eventNames().forEach(event => {
            element.removeAllListeners(event);
        })
    }

    destroy(sendFinish = true) {
        console.info("destroy", sendFinish);
        return new Promise((resolve, reject) => {
            document.body.classList.remove("vtail-body-wrap");
            this.finishBroadcast(sendFinish)
                .then(() => {
                    wss.close();
                    if (this.productsList) {
                        this.removeListeners(this.productsList);
                        this.productsList.destroy();
                    }
                    if (this.takeScreenshot) {
                        this.removeListeners(this.takeScreenshot);
                        this.takeScreenshot.destroy();
                    }

                    if (this.countDownInterval) {
                        clearInterval(this.countDownInterval);
                    }

                    Object.values(this.controls).forEach(control => {
                        this.removeListeners(control);
                    });
                    this.removeListeners(this);
                    // this.playerWrapper.remove();

                    resolve();
                })
                .catch(error => reject(error));
        });


        /*return new Promise((resolve, reject) => {
            this.finishBroadcast(sendFinish)
                .then(()=>{
                    this.removeListeners(this);
                    console.info(wss.eventNames());

                    wss.off(WSS_CODES.OFFER, this.onOffer);
                    wss.off(WSS_CODES.ONLINE_USERS, this.onOnlineUsers);
                    wss.off(WSS_CODES.ONLINE_USER, this.onOnlineUser);
                    wss.off(WSS_CODES.OFFLINE_USER, this.onOfflineUser);
                    wss.off(WSS_CODES.BROADCAST_FINISH, this.onBroadcastFinish);
                    wss.off("onclose", this.onWSSclose);
                    wss.off("onreconncet", this.onWSSreconnect);

                    if (this.productsList){
                        this.removeListeners(this.productsList);
                        this.productsList.destroy();
                    }
                    if (this.takeScreenshot){
                        this.removeListeners(this.takeScreenshot);
                        this.takeScreenshot.destroy();
                    }

                    Object.values(this.controls).forEach(control=>{
                        this.removeListeners(control);
                    });

                    resolve();
                });
        });*/
    }
}
