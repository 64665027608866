'use strict';

export default class Debug {
    constructor(prefix, color, background) {
        this.prefix = prefix;
        this.color = color;
        this.background = background;
        // if (!IS_PROD) {
        // if (localStorage.getItem("VTail-dev")) {
        if (true) {
            this.reBind();
        } else {
            this.log = () => {};
            this.info = () => {};
            this.warn = () => {};
            this.error = () => {};
            this.dir = () => {};
            this.debug = () => {};
            this.trace = () => {};
        }
    }
    reBind(){
        this.log = window.console.log.bind(window.console, "%c" + this.prefix, "background: " + this.background + "; color:  " + this.color);
        this.info = window.console.info.bind(window.console, "%c" + this.prefix, "background: " + this.background + "; color:  " + this.color);
        this.warn = window.console.warn.bind(window.console, "%c" + this.prefix, "background: " + this.background + "; color:  " + this.color);
        this.error = window.console.error.bind(window.console, "%c" + this.prefix, "background: " + this.background + "; color:  " + this.color);
        this.dir = window.console.dir.bind(window.console);
        this.debug = window.console.debug.bind(window.console, "%c" + this.prefix, "background: " + this.background + "; color:  " + this.color);
        this.trace = window.console.trace.bind(window.console, "%c" + this.prefix, "background: " + this.background + "; color:  " + this.color);
    }

    setSubPrefix(subPrefix){
        console.info("setSubPrefix", this.prefix, subPrefix)
        this.prefix=this.prefix + " | " + subPrefix;
        this.reBind();
    }
}
