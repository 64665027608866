<template>
  <div class="prepare">
    <h1>Preparation</h1>
    <div class="prepare__wrap">
      <div class="prepare__col">
        <div class="prepare__video-wrap">
          <video ref="localVideo" class="prepare__video" playsinline></video>
          <!--          <div class="prepare__video"></div>-->
        </div>
        <div class="prepare__buttons">
          <button class="prepare__button video-control video-control__mic" :class="{off: muted}" @click="onMicClick"></button>
<!--          <div class="prepare__button video-control video-control__mic off"></div>-->
          <button class="prepare__button video-control video-control__mask" :class="{off: !anonym}" @click="onCameraClick"></button>
<!--          <div class="prepare__button video-control video-control__mask off"></div>-->
        </div>
      </div>
<!--      <div class="prepare__col">
        <div class="prepare__form">
          <h3>What should we call you?</h3>
          <p>
            It is not necessary to write your real name, you can choose any that you like
          </p>
          <input type="text" placeholder="Enter your name">
        </div>
      </div>-->
    </div>
    <button @click="onNextClick" class="prepare__button button">Next</button>
  </div>
<!--  <yuri>
    <video ref="localVideo" width="500" height="500" style="background: red"></video>
    <button @click="onMicClick">mic</button>
    <button @click="onCameraClick">cam</button>
    <button @click="onNextClick">next</button>
  </yuri>-->
</template>

<script>


// import "jungle.js"
import  "@/assets/js/jungle";
import {AUTH_USER} from "@/vtail/Auth";
import auth from "@/vtail/Auth";
import {AVATARS64} from "@/vtail/avatars";
import router from "@/router";
let Jungle = window.Jungle;
export default {
  name: "GetReady",
  components: {
  },
  data() {
    return {
      localStream : {
        stream: null,
        audioTrack: {},
        audioTrackAnonym: {},
        audioTrackOrigin: {},
        videoTrack: {},
        avatarTrack: {},
        screenShareTrack: null
      },
      mediaStream: {},
      avatarStream: {},

      anonym: true,
      muted: false,
      meetID: this.$route.params.meetID
    }
  },
  mounted() {
    console.info("GetReady", this.meetID);
    this.getUserMedia();
  },
  methods: {
    onMicClick(){
      console.info("onMicClick");
      if (this.muted){
        this.localStream.audioTrackAnonym.enabled = true;
        this.localStream.audioTrackOrigin.enabled = true;
        this.muted = false;
      } else {
        this.localStream.audioTrackAnonym.enabled = false;
        this.localStream.audioTrackOrigin.enabled = false;
        this.muted = true;
      }
      // this.localStream.audioTrackAnonym.enabled = true;
      // this.localStream.audioTrackOrigin.enabled = true;

      // this.localStream.audioTrackAnonym.enabled = false;
      // this.localStream.audioTrackOrigin.enabled = false;
    },
    onCameraClick(){
      console.info("onCameraClick");
      if (this.anonym){
        this.replaceTrack(this.localStream.videoTrack)
        this.anonym = false;
      } else {
        this.replaceTrack(this.localStream.avatarTrack)
        this.anonym = true;
      }
    },
    onNextClick(){
      console.info("onNextClick", auth);

      /*auth.updateUser({
        mediaStream:{
          videoTrack: this.localStream.videoTrack,
          avatarTrack: this.localStream.avatarTrack,
          audioTrackAnonym: this.localStream.audioTrackAnonym,
          audioTrackOrigin: this.localStream.audioTrackOrigin,
        }
      })*/

      this.stopAvatarStream();

      if (this.localStream && this.localStream.stream) {
        this.localStream.stream.getTracks().forEach(track => track.stop());
      }



      router.push({
        name:   'MeetingPage',
        params: {
          meetID: this.meetID,
          anonym: this.anonym,
          muted:  this.muted
        }
      });
    },

    getUserMedia() {
      console.info("getUserMedia");
      navigator.mediaDevices.getUserMedia({audio:true, video:true})
          .then(mediaStream => {
            let audioTracks = mediaStream.getAudioTracks();
            let videoTracks = mediaStream.getVideoTracks();

            let audioContext = new AudioContext();

            let audioSources = audioContext.createMediaStreamSource(mediaStream);

            let pitchChangeEffect = new Jungle( audioContext );

            let compressor = audioContext.createDynamicsCompressor();

            audioSources.connect(pitchChangeEffect.input)
            pitchChangeEffect.output.connect(compressor)
            pitchChangeEffect.setPitchOffset(.3);

            let peer = audioContext.createMediaStreamDestination();
            compressor.connect(peer);

            console.info("AC peer.stream", peer.stream);
            peer.stream.getTracks().forEach(track => {
              console.info("AC peer.stream track", track);
            });

            let audioTracksPeer = peer.stream.getAudioTracks();
            this.localStream.audioTrackAnonym = audioTracksPeer[0];
            this.localStream.audioTrackOrigin = audioTracks[0];

            if (videoTracks.length) {
              this.localStream.videoTrack = videoTracks[0];
            }
            this.localStream.stream = mediaStream;
            console.info("this.localStream.stream", this.localStream.stream)

            this.createAvatarStream(500, 500);

          })
    },

    createAvatarStream(width, height, imageSrc) {
      return new Promise((resolve, reject) => {
        console.info("createAvatarStream");
        this.avatarStream.image= new Image();
        // this.avatarStream.image.crossOrigin="anonymous";
        this.avatarStream.image.onload = (image) => {
          let canvas = Object.assign(document.createElement("canvas"), {width, height});
          this.avatarStream.context = canvas.getContext("2d");

          let stream = canvas.captureStream();
          this.localStream.avatarTrack = Object.assign(stream.getVideoTracks()[0], {enabled: true});
          this.startAvatarStream();
          this.mediaStream = new MediaStream([this.localStream.audioTrackAnonym, this.localStream.avatarTrack]);
          console.info("this.mediaStream", this.mediaStream);
          this.$refs.localVideo.srcObject = this.mediaStream;
          // this.$refs.localVideo.srcObject = this.localStream.stream;

          this.$refs.localVideo.play();
          console.info("this.$refs.localVideo", this.$refs.localVideo)
          resolve();
        };
        // this.avatarStream.image.src = imageSrc;
        // this.avatarStream.image.src = 'https://ipfs.io/ipfs/QmR36VFfo1hH2RAwVs4zVJ5btkopGip5cW7ydY4jUQBrKW';
        // AUTH_USER.avatar = AVATARS64[Math.floor(Math.random()*4)];
        this.avatarStream.image.src = 'data:image/png;base64,'+AUTH_USER.avatar;
        // this.avatarStream.image.src = 'http://www.flexbricks.com/img/logo.png';

      });
    },

    startAvatarStream() {
      console.info("startAvatarStream");
      this.avatarStream.iterval = setInterval(() => {
        this.avatarStream.context.drawImage(this.avatarStream.image,
            0, 0,
            this.avatarStream.image.width, this.avatarStream.image.height,
            0, 0,
            500, 500
        );
        // console.info("DRAW", this.timestamp);
      }, 1000);
    },

    stopAvatarStream() {
      console.info("stopAvatarStream");
      if (this.avatarStream.iterval) {
        clearInterval(this.avatarStream.iterval);
      }
    },

    replaceTrack(newTrack) {
      console.info("replaceTrack", newTrack);
      this.mediaStream.getTracks().forEach(oldTrack=>{
        if (newTrack.kind == oldTrack.kind){
          this.mediaStream.removeTrack(oldTrack);
        }
      });
      this.mediaStream.addTrack(newTrack);
    },
  },
}
</script>

<style scoped>

</style>