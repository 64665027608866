'use strict';
import Debug from "./Debug";
import Dictionary from "./TranslatorDictionary";

const console = new Debug("  TranslatorClass  ", "#000", "#ffeb3b");

class TranslatorClass {
    constructor(language = 'en') {
        console.info("constructor");
        if (TranslatorClass.Instance) {
            return TranslatorClass.Instance;
        }
        TranslatorClass.Instance = this;
        this.language = language;
        this.dictionary = new Map(Object.entries(Dictionary));
    }

    get(key) {
        let node = this.dictionary.get(key);
        if (node) {
            let text = node[this.language];
            if (text) {
                return text;
            }
        }
        console.error('Translation not found', key, this.language);
        return key;
    }

    getWeekday(date) {
        return this.get('calendar_' + Intl.DateTimeFormat('en', {weekday: 'long'}).format(date).toLowerCase());
    }

    getWeekdayShort(date) {
        return this.get('calendar_' + Intl.DateTimeFormat('en', {weekday: 'short'}).format(date).toLowerCase());
    }

    getMonth(date) {
        return this.get('calendar_' + Intl.DateTimeFormat('en', {month: 'long'}).format(date).toLowerCase());
    }
}

const translator = new TranslatorClass();
export default translator;
