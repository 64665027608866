'use strict';

import {EventEmitter} from "events";
import Debug from "./Debug";
import translator from "./Translator";

const console = new Debug("  ControlButton  ", "#FFF", "#00bcd4");

export default class ControlButton extends EventEmitter {
    constructor(element) {
        super();
        this.element = element;
        console.info(this.element);
        this.state = {};

        this.element.addEventListener('click', (event) => {
            if (!this.element.classList.contains('vtail-disabled')) {
                this.emit("click", event);
            }
        }, false)
    }

    enable() {
        this.disabled = false;
        this.element.classList.remove("vtail-disabled");
        this.element.disabled = false;
    }

    disable() {
        this.disabled = true;
        this.element.classList.add("vtail-disabled");
        this.element.disabled = true;
    }

    hide() {
        this.hidden = true;
        this.element.classList.add("vtail-hidden");
    }

    show() {
        this.hidden = false;
        this.element.classList.remove("vtail-hidden");
    }

    classToggle(className, conditions) {
        this.element.classList.toggle(className, conditions);
    }

    setActive() {
        this.isActive = true;
        this.element.classList.add('active');
    }

    unsetActive() {
        this.isActive = false;
        this.element.classList.remove('active');
    }

    setState(state) {
        console.info("setState",state)
        this.state = state;
        if (this.stateClasses){
            // let cls = ["vtail-player-button_voice-request", "vtail-player-button_voice-decline", "vtail-player-button_voice-end"];
            this.element.classList.remove(...Object.values(this.stateClasses));
            this.element.classList.add(this.stateClasses[state]);
        }
        if (this.stateTitles){
            this.element.setAttribute("title", this.stateTitles[state]);
        }
    }
    setStateClasses(map) {
        console.info("setStateClasses", map)
        this.stateClasses = map;
    }
    setStateTitles(map) {
        console.info("setStateTitles", map)
        this.stateTitles = map;
    }

    getState() {
        return this.state;
    }

    remove(){
        this.element.remove();
        this.element = null;
    }

    /*setState(stateName, value) {
        this.state[stateName] = value;
    }

    getState(stateName) {
        this.state[stateName];
    }*/

}
