<template>
  <header>
    <router-link to="/" class="logo">Sidekey</router-link>
  </header>
</template>

<script>
export default {
  name: "Header"
}
</script>

<style scoped>

</style>