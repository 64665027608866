'use strict';

import {EventEmitter} from "events";
import Debug from "./Debug";
import api from "./API";
import wss from "./WSS";


// const console = new Debug("  BroadcastConnection  ", "#FFF", "#83ff50");
let console = {};
// const api = new API();
// const wss = new WSSClass({});
export default class BroadcastConnection extends EventEmitter {
    constructor(options) {
        super();
        // console.info("constructor", options);

        console = new Debug("  BroadcastConnection", "#000", (options.user_role == 'client') ? "#83ff50" : "#aa0000", false);
        // console.info("options.user_role=='client'", options.user_role=='client');
        // console.setSubPrefix(options.user_role);
        this.options = options;
        console.info("options", this.options);

        this.stream = options.localStream;

        console.info("!!!!!!!!!!!!!!!!!!!!!this.stream", this.stream, this.stream.getTracks());

        this.peerConnection = new RTCPeerConnection(options.configuration);

        this.peerConnection.onicecandidate = (iceEvent) => {
            if (iceEvent && iceEvent.candidate) {
                api.webRtcSendCandidate(this.options.broadcastID, this.options.receiverSessionID, {
                    candidate: iceEvent.candidate.candidate,
                    sdpMLineIndex: iceEvent.candidate.sdpMLineIndex,
                    sdpMid: iceEvent.candidate.sdpMid,
                    sender_session_id: wss.SESSION_ID
                })
                    .then(data => {
                        console.info("webRtcSendCandidate", data);
                    })
                    .catch(error => {
                        console.error("webRtcSendCandidate", error.name, error.message, error);
                    })
            }
        };


        let _self = this;


        this.peerConnection.ontrack = (event) => {
            console.info("this.peerConnection.ontrack", event);
            // if (event.kind.kind =='audio'){
            this.options.mediaElement.srcObject = event.streams[0];
            console.info("this.options.mediaElement.srcObject", this.options.mediaElement.srcObject.getTracks());
            this.emit("ontrack", event);
            // }

        };
        this.peerConnection.onnegotiationneeded = function (event) {
            console.info("onnegotiationneeded", event);
        };


        /**/
        this.peerConnection.onconnectionstatechange = function (event) {
            console.info("onconnectionstatechange", _self.peerConnection.connectionState, event);
        };
        this.peerConnection.oniceconnectionstatechange = function (event) {
            console.info("oniceconnectionstatechange ", _self.peerConnection.iceConnectionState, event);
        };
        this.peerConnection.onicegatheringstatechange = function (event) {
            console.info("onicegatheringstatechange  ", _self.peerConnection.iceGatheringState, event);
        };
        this.peerConnection.onidentityresult = function (event) {
            console.info("onidentityresult   ", event);
        };
        this.peerConnection.onidpassertionerror = function (event) {
            console.info("onidpassertionerror    ", event);
        };
        this.peerConnection.onidpvalidationerror = function (event) {
            console.info("onidpvalidationerror     ", event);
        };

        this.peerConnection.onremovestream = function (event) {
            console.info("onremovestream       ", event);
        };

        this.peerConnection.onsignalingstatechange = function (event) {
            console.info("onsignalingstatechange        ", event, _self.peerConnection.signalingState);
        }
        /**/

        //TODO - show message that camera may use on other page

        /*wss.on("offer", data=>{
            console.info("on offer", data);
            this.onOffer(data);
        });
        wss.on("answer", data=>{
            console.info("on answer", data);
            this.onAnswer(data);
        });

        wss.on("candidate", data=>{
            console.info("on candidate", data);
            this.onCandidate(data);
        });*/

        this.onOffer = this.onOffer.bind(this);
        this.onAnswer = this.onAnswer.bind(this);
        this.onCandidate = this.onCandidate.bind(this);

        // wss.on("offer", this.onOffer);
        wss.on("answer", this.onAnswer);
        wss.on("candidate", this.onCandidate);

        if (options.offer) {
            this.onOffer(options.offer);
        } else {
            this.sendOffer();
        }
    }

    sendOffer() {
        console.info("sendOffer");
        this.stream.getTracks().forEach(track => {
            console.info("********track", track);

            this.sender = this.peerConnection.addTrack(track, this.stream)
        });
        this.peerConnection.createOffer({
            iceRestart: true,
            offerToReceiveVideo: true,
            offerToReceiveAudio: true
        })
            .then(offer => {
                console.info("createOffer", offer);
                return this.peerConnection.setLocalDescription(offer);
            })
            .then(() => {
                console.info("setLocalDescription", this.peerConnection.localDescription);
                return api.webRtcSendOffer(this.options.broadcastID, this.options.receiverSessionID, {
                    type: this.peerConnection.localDescription.type,
                    sdp: this.peerConnection.localDescription.sdp,
                    sender_session_id: wss.SESSION_ID
                })
            })
            .then(data => {
                console.info("webRtcSendOffer", data);
            })
            .catch(error => {
                console.error("sendOffer", error.name, error.message, error);
            });
    }

    onOffer(offer) {
        console.info("onOffer", offer);
        console.info("onOffer", offer.sender_session_id, this.options.receiverSessionID, offer.sender_session_id != this.options.receiverSessionID);

        if (offer.sender_session_id != this.options.receiverSessionID) return;
        this.peerConnection.setRemoteDescription(offer)
            .then(() => {
                console.info("setRemoteDescription");
                this.stream.getTracks().forEach(track => {
                    this.peerConnection.addTrack(track, this.stream)
                });
                return this.peerConnection.createAnswer();
            })
            .then((answer) => {
                console.info("createAnswer", answer);
                return this.peerConnection.setLocalDescription(answer);
            })
            .then(() => {
                console.info("setLocalDescription");
                return api.webRtcSendAnswer(this.options.broadcastID, this.options.receiverSessionID, {
                    type: this.peerConnection.localDescription.type,
                    sdp: this.peerConnection.localDescription.sdp,
                    sender_session_id: wss.SESSION_ID
                })
            })
            .then(data => {
                console.info("webRtcSendAnswer", data);
            })
            .catch(error => {
                console.error("onOffer", error.name, error.message, error);
            });
    }

    onAnswer(answer) {
        console.info("onAnswer", answer);
        console.info("onAnswer", answer.sender_session_id, this.options.receiverSessionID, answer.sender_session_id != this.options.receiverSessionID);
        if (answer.sender_session_id != this.options.receiverSessionID) return;
        this.peerConnection.setRemoteDescription(answer)
            .then(data => {
                console.info("onAnswer setRemoteDescription", data);
            })
            .catch(error => {
                console.error("onAnswer setRemoteDescription", error);
            });
    }

    onCandidate(candidate) {
        console.info("onCandidate", candidate);
        console.info("onCandidate", candidate.sender_session_id, this.options.receiverSessionID, candidate.sender_session_id != this.options.receiverSessionID);
        if (candidate.sender_session_id != this.options.receiverSessionID) return;
        this.peerConnection.addIceCandidate(candidate)
            .catch(error => {
                console.log("onCandidate addIceCandidate", error);
            });
    }
    replaceTrack(track) {
        console.info("replaceTrack", track);
        let sender = this.peerConnection.getSenders().find((sender) => {
            console.info(sender);
            return sender.track.kind == track.kind;
        });
        console.log('found sender:', sender);
        sender.replaceTrack(track);
    }

    removeTrack(track) {
        console.info("removeTrack", track);
        let sender = this.peerConnection.getSenders().find((sender) => {
            if (sender.track.kind == track.kind) {

                console.info("sender", sender)
                this.peerConnection.removeTrack(sender);
            }
            // this.peerConnection.removeTrack(sender);
            // return sender.track.kind == track.kind;
        });
        // this.peerConnection.removeTrack(sender);
        // sender.replaceTrack(track);
    }

    addTrack(stream) {
        this.stream = stream;
        this.stream.getTracks().forEach(track => {
            this.sender = this.peerConnection.addTrack(track, this.stream)
        });
    }


    close() {
        if (this.peerConnection) {
            this.peerConnection.ontrack = null;
            this.peerConnection.onremovetrack = null;
            this.peerConnection.onremovestream = null;
            this.peerConnection.onicecandidate = null;
            this.peerConnection.oniceconnectionstatechange = null;
            this.peerConnection.onsignalingstatechange = null;
            this.peerConnection.onicegatheringstatechange = null;
            this.peerConnection.onnegotiationneeded = null;

            /*if (this.options.mediaElement.srcObject) {
                this.options.mediaElement.srcObject.getTracks().forEach(track => track.stop());
            }*/
            this.peerConnection.close();
            this.peerConnection = null;
        }

        // this.options.mediaElement.removeAttribute("src");
        // this.options.mediaElement.removeAttribute("srcObject");

        wss.off("offer", this.onOffer);
        wss.off("answer", this.onAnswer);
        wss.off("candidate", this.onCandidate);
        this.removeAllListeners();
    }
}
/*
https://developer.mozilla.org/en-US/docs/Web/API/WebRTC_API/Signaling_and_video_calling


function closeVideoCall() {
  var remoteVideo = document.getElementById("received_video");
  var localVideo = document.getElementById("local_video");

  if (myPeerConnection) {
    myPeerConnection.ontrack = null;
    myPeerConnection.onremovetrack = null;
    myPeerConnection.onremovestream = null;
    myPeerConnection.onicecandidate = null;
    myPeerConnection.oniceconnectionstatechange = null;
    myPeerConnection.onsignalingstatechange = null;
    myPeerConnection.onicegatheringstatechange = null;
    myPeerConnection.onnegotiationneeded = null;

    if (remoteVideo.srcObject) {
      remoteVideo.srcObject.getTracks().forEach(track => track.stop());
    }

    if (localVideo.srcObject) {
      localVideo.srcObject.getTracks().forEach(track => track.stop());
    }

    myPeerConnection.close();
    myPeerConnection = null;
  }

  remoteVideo.removeAttribute("src");
  remoteVideo.removeAttribute("srcObject");
  localVideo.removeAttribute("src");
  remoteVideo.removeAttribute("srcObject");

  document.getElementById("hangup-button").disabled = true;
  targetUsername = null;
}
* */