'use strict';
import Debug from "./Debug";
import {AUTH_USER} from "./Auth";
import {CONFIG} from "./VARs";

// const API_PATH = 'https://dev-quickstart-api.vtail.live/api';
// const API_PATH = 'https://api.vtail.live/api';
// const API_PATH = 'https://marketplace.vtail.live/api';
// const API_PATH = 'https://test01-marketplace.vtail.live/api';
// const API_KEY = 'a893dbdf-ecde-450c-9c50-71f34d5d70ce';
 const API_PATH = process.env.VUE_APP_VTAIL_API_URL;

 window.console.info("API_PATH", API_PATH)
window.console.info("API_PATH", process.env)

export const ERROR_CODES = {
    USER_NOT_FOUND: 401,
    USER_BANNED: 403,
};

const console = new Debug("  API  ", "#FFF", "#f555ec");

class APIClass {
    constructor(options = {}) {
        console.info("constructor", options);
        if (APIClass.Instance) {
            return APIClass.Instance;
        }
        APIClass.Instance = this;

        // this.TOKEN = options.token;


        /*this.auth = new AuthClass();
        this.onAuth = this.onAuth.bind(this);
        this.auth.on('auth',this.onAuth)*/
    }

    /*onAuth(user){
        console.info("onAuth", user);
        this.setToken(user.token);
    }*/

    /*    setToken(token) {
            console.info("setToken", token);
            this.TOKEN = token;
        }

        getToken(){
            // console.info("getToken", localStorage.getItem(AUTH_STORAGE.TOKEN));
            return localStorage.getItem(AUTH_STORAGE.TOKEN);
        }*/

    _sendRequest(method, path, data = null) {
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open(method, API_PATH + path);
            if (CONFIG.API_KEY) {
                xhr.setRequestHeader('X-API-KEY', CONFIG.API_KEY);
            }
            // let token = this.getToken();
            let token = AUTH_USER.token;
            if (token) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + token);
            }
            if (data instanceof FormData) {
                //xhr.setRequestHeader('Content-Type', 'multipart/form-data');
            } else {
                xhr.setRequestHeader('Content-Type', 'application/json');
                data = JSON.stringify(data);
            }
            xhr.onload = function () {
                if (this.status === 200) {
                    let response = JSON.parse(xhr.responseText);
                    if (response.error_code === 0) {
                        resolve(response.object);
                    } else {
                        reject({
                            status: this.status,
                            statusText: xhr.statusText,
                            errorCode: response.error_code,
                            errorMessage: response.error_message
                        });
                    }
                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText
                    });
                }
            };
            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            };
            xhr.send(data);
        });
    }

    get(path) {
        return this._sendRequest("GET", path);
        /*return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            // xhr.open("GET", API_CHAT_PATH + path);
            xhr.open("GET", API_PATH + path);
            if (this.TOKEN) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + this.TOKEN);
            }
            xhr.onload = function () {
                if (this.status === 200) {
                    let response = JSON.parse(xhr.responseText);
                    if (response.error_code === 0) {
                        if (response.object){
                            resolve(response.object);
                        } else {
                            resolve({ response: response });
                        }
                    } else {
                        reject({
                            status: this.status,
                            statusText: xhr.statusText,
                            errorCode: response.error_code,
                            errorMessage: response.error_message
                        });
                    }
                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText
                    });
                }
            };
            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            };
            xhr.send();
        });*/
    }

    post(path, data) {
        return this._sendRequest("POST", path, data);
        /*return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open("POST", API_PATH + path);
            if (this.TOKEN) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + this.TOKEN);
            }
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.onload = function () {
                if (this.status === 200) {
                    let response = JSON.parse(xhr.responseText);
                    if (response.error_code === 0) {
                        if (response.object){
                            resolve(response.object);
                        } else {
                            resolve({ response: response });
                        }
                    } else {
                        reject({
                            status: this.status,
                            statusText: xhr.statusText,
                            errorCode: response.error_code,
                            errorMessage: response.error_message
                        });
                    }
                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText
                    });
                }
            };
            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            };
            xhr.send(JSON.stringify(data));
        });*/
    }

    postFile(path, file) {

        let formData = new FormData();
        formData.append("file", file, 'file.jpeg');

        return this._sendRequest("POST", path, formData)
        /*return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open("POST", API_PATH + path);
            if (this.TOKEN) {
                xhr.setRequestHeader('Authorization', 'Bearer ' + this.TOKEN);
            }
            xhr.setRequestHeader('Content-Type', 'multipart/form-data');
            xhr.onload = function () {
                if (this.status === 200) {
                    let response = JSON.parse(xhr.responseText);
                    if (response.error_code === 0) {
                        if (response.object){
                            resolve(response.object);
                        } else {
                            console.error("Object is required in response", response, xhr);
                        }
                    } else {
                        reject({
                            status: this.status,
                            statusText: xhr.statusText,
                            errorCode: response.error_code,
                            errorMessage: response.error_message
                        });
                    }
                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText
                    });
                }
            };
            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            };
            let formData = new FormData();
            formData.append("file", file);
            xhr.send(formData);
        });*/
    }

    /*------------------------------------------------------------------------------------------------------*/

    /*stores*/
    storesGetStoreConfig() {
        return this.get("/stores/get_store_config?token=" + AUTH_USER.session_id);
    }

    /*/stores*/

    /*broadcast*/
    broadcastGetBroadcastByLastRequest() {
        return this.post("/broadcasts/get_broadcast_by_last_request");
    }

    broadcastNewPrivateBroadcastRequest(data) {
        return this.post("/broadcasts/new_private_broadcast_request", data);
    }

    broadcastDeleteMyPrivateBroadcast(broadcastID) {
        return this.post("/broadcasts/delete_my_private_broadcast/" + broadcastID);
    }

    broadcastDeleteMyPrivateBroadcastRequest() {
        return this.post("/broadcasts/delete_my_private_broadcast_request");
    }

    broadcastGetTimeSlots(data) {
        return this.post("/broadcasts/get_time_slots", data);
    }

    /*broadcastGetTimeSlots(timeZone, dateFrom, dateTo) {
        return this.post("/broadcasts/get_time_slots",{
            "time_zone": timeZone,
            "date_from": dateFrom,
            "date_to": dateTo
        });
    }*/
    /* /broadcast*/

    /*------------------------------------------------------------------------------------------------------*/

    /*public broadcast*/
    publicBroadcastList() {
        return this.get("/public_broadcast/list");
    }

    publicBroadcast(broadcastID) {
        return this.get("/public_broadcast/" + broadcastID);
    }

    publicBroadcastProducts(broadcastID) {
        return this.get("/public_broadcast/products/" + broadcastID);
    }

    publicBroadcastNewLike(broadcastID) {
        return this.post("/public_broadcast/new_like/" + broadcastID);
    }

    publicBroadcastLikes(broadcastID) {
        return this.get("/public_broadcast/likes/" + broadcastID);
    }

    publicBroadcastNewRequestedProduct(broadcastID, data) {
        return this.post("/public_broadcast/new_requested_product/" + broadcastID, data);
    }
    publicBroadcastSetCompletedViewDuration({broadcastID, viewedDuration}) {
        return this.post(`/public_broadcast/set_completed_view_duration/${broadcastID}/${viewedDuration}`);
    }
    /* audio request*/
    publicBroadcastAudioRequestNew(broadcastID){
        return this.post("/public_broadcast/audio_request/" + broadcastID + "/new");
    }
    publicBroadcastAudioRequestUserDecline(broadcastID){
        return this.post("/public_broadcast/audio_request/" + broadcastID + "/user_decline");
    }
    publicBroadcastAudioRequestUserDrop(broadcastID){
        return this.post("/public_broadcast/audio_request/" + broadcastID + "/user_drop");
    }
    publicBroadcastVoiceChatConfig(broadcastID){
        return this.get("/public_broadcast/voice_chat_config/" + broadcastID);
    }
    /* /audio request*/

    /* /broadcast*/

    /*------------------------------------------------------------------------------------------------------*/

    /* products*/
    productsProductShowRequest(data) {
        return this.post("/products/product_show_request", data);
    }

    productsGetProducts(broadcastID) {
        return this.get("/products/get_products/" + broadcastID);
    }


    /* /products*/
    /*------------------------------------------------------------------------------------------------------*/

    /* video-stream*/
    videostreamGetConfig(broadcastID) {
        return this.post("/video-stream/get_config/" + broadcastID);
    }

    /* /video-stream*/

    /*------------------------------------------------------------------------------------------------------*/

    /* image*/
    imageUpload(file) {
        return this.postFile("/images/upload", file);
    }

    imagesToBase64(data) {
        return this.post("/images/image_to_base64", data);
    }

    /* /image*/


    /*------------------------------------------------------------------------------------------------------*/

    /* chat*/
    chatGetLast(broadcastID) {
        return this.get("/chat/" + broadcastID + "/last");
    }

    chatGetAll(broadcastID) {
        return this.get("/chat/" + broadcastID + "/all");
    }

    chatSend(broadcastID, data) {
        return this.post("/chat/" + broadcastID + "/send", data);
    }

    /* /chat*/

    /*------------------------------------------------------------------------------------------------------*/

    /* user*/
    userAuthClient(data) {
        return this.post("/users/auth_client", data);
    }

    userGetClientState() {
        return this.get("/users/get_client_state");
    }

    userPingUser() {
        return this.get("/users/ping_user?token=" + AUTH_USER.session_id);
    }
    /* /user*/

    /* webRtc*/
    webRtcSendAnswer(broadcastID, userSessionID, answer) {
        return this.post("/web_rtc/send_answer/" + broadcastID + "/" + userSessionID, answer);
    }

    webRtcSendOffer(broadcastID, userSessionID, offer) {
        return this.post("/web_rtc/send_offer/" + broadcastID + "/" + userSessionID, offer);
    }

    webRtcSendCandidate(broadcastID, userSessionID, candidate) {
        return this.post("/web_rtc/send_candidate/" + broadcastID + "/" + userSessionID, candidate);
    }

    webRtcGetConfig(broadcastID) {
        return this.post("/web_rtc/get_config/" + broadcastID);
    }
    /* /webRtc*/

    /* Invites*/
    invitesAddUserToBroadcast(broadcastID) {
        return this.post("/invites/add_user_to_broadcast/" + broadcastID);
    }

    /* /Invites*/

    /* firebase api*/

    firebaseShortLinks(broadcast) {
        return new Promise((resolve, reject) => {
            let FB_API_KEY = "AIzaSyDd01iW_j7n2VGr9so-nQ7WdbH12q-coPI";
            // https://vtailclient.page.link/?link=https://marketplace.vtail.live/watch?id%3D{{broadcast_id}}&isi=1559553206&ibi=com.vtail.marketplace.client&ifl=https://marketplace.vtail.live/watch?id%3D{{broadcast_id}}&efr=1&st={{title}}
            let IOS_URI = {
                url: 'https://vtailclient.page.link/',
                link: 'https://marketplace.vtail.live/watch?vtail-broadcast=' + broadcast.id,
                ibi: 'com.vtail.marketplace.client',
                isi: '1559553206',
                efr: '1',
                ifl: 'https://marketplace.vtail.live/watch?vtail-broadcast=' + broadcast.id,
                st: broadcast.name,
                si: broadcast.image,
            };
            let url = new URL(IOS_URI.url);
            for (const [key, value] of Object.entries(IOS_URI)) {
                url.searchParams.set(key, value);
            }
            let data = {
                "longDynamicLink": url.href
            };
            data = JSON.stringify(data);
            let xhr = new XMLHttpRequest();

            xhr.open("POST", "https://firebasedynamiclinks.googleapis.com/v1/shortLinks?key=" + FB_API_KEY);

            xhr.setRequestHeader('Content-Type', 'application/json');

            xhr.onload = function () {
                if (this.status === 200) {
                    let response = JSON.parse(xhr.responseText);
                    resolve(response);
                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText
                    });
                }
            };
            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            };
            xhr.send(data);
        });
    }

    /* firebase api*/


    /*sidekey*/

    usersCreateClient(storeUserID){
        return this.post("/users/create_client/" + storeUserID);
    }
    /*broadcastsNewPrivateBroadcast(storeUserID){
        return this.post("/broadcasts/new_private_broadcast/" + storeUserID);
    }*/
    broadcastsGetPrivateBroadcast(broadcastID){
        return this.post("/broadcasts/get_broadcast_by_id/" + broadcastID);
    }
    broadcastsGetBroadcastName(broadcastID){
        return this.get("/broadcasts/get_broadcast_name_by_id/" + broadcastID);
    }
    broadcastsNewPrivateBroadcast(data){
        return this.post("/broadcasts/new_private_broadcast", data);
    }
    broadcastsCheckAccessBroadcast(broadcastID, walletAddress){
        return this.get("/broadcasts/check_access_broadcast/" + broadcastID +"/" + walletAddress);
    }

    alchemyGetImages(address_id){
        return this.get("/alchemy/get_images/" + address_id );
    }

    ethplorerGetTokenInfo(address){
        return new Promise((resolve, reject) => {
            let xhr = new XMLHttpRequest();
            xhr.open("GET", "https://api.ethplorer.io/getTokenInfo/"+address+"?apiKey=EK-57Z1s-6wj59oS-5EoNo");
            // xhr.setRequestHeader('Content-Type', 'application/json');

            xhr.onload = function () {
                if (this.status === 200) {
                    let response = JSON.parse(xhr.responseText);
                    resolve(response);
                } else {
                    reject({
                        status: this.status,
                        statusText: xhr.statusText
                    });
                }
            };
            xhr.onerror = function () {
                reject({
                    status: this.status,
                    statusText: xhr.statusText
                });
            };
            xhr.send();
        });
    }




/*/sidekey*/

}

const api = new APIClass();
export default api;