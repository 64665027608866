export const WATCH_LIVE_BUTTON_ACTION = {
    OPEN_FAF: 'open_faf',
    VIDEO_CALL: 'video_call',

};

export const IS_PROD = process.env.NODE_ENV === 'production';


export const PING_TIMEOUT = 30000;
export const CHECK_USER_STATE_TIMEOUT = 3000;
export const CONFIG = {
    //ПАРАМЕТР СТОРА, выдавать при интегрнации
    API_KEY: null,//api key for api
    LANGUAGE: 'en',

    WATCH_LIVE_BUTTON: true,
    ENABLE_FAF: true,
    WATCH_LIVE_BUTTON_ACTION: WATCH_LIVE_BUTTON_ACTION.OPEN_FAF, //video_call, open_faf

    PRIVATE_ASAP_REQUEST: true,
    PRIVATE_SCHEDULED_REQUEST: true,
    PRIVATE_DURATION: 15,

    WORKING_HOURS: false,
    DISABLE_NON_WORKING_HOURS: false,
    DISABLE_NON_ENOUGH_STREAMERS: false,
    ENOUGH_STREAMERS: true,

    PRIVATE_PRODUCT_LIST: true,
    GUEST_PRIVATE_SESSION: false,

    USER: null,/*{ID: "",NAME: "John",IS_GUEST:false}*/

    /*UTM: {
        UTM_SOURCE: '',
        UTM_MEDIUM: '',
        UTM_CAMPAIGN: '',
    },*/

    INLINE_MODE: false, // нафиг

    STYLES: {
        // base_color:'red',
        // color_buttons_text:'green',
        // button_radius:'5px',
        // input_radius:'6px',
    }

    // ENABLED: true,// enable watch live button and FAF
    // APP_BANNER: false, // нафиг
    // WATCH_LIVE_BUTTON: true,
    // FAF: true, //нужен всегда при вотчлайв, если нет вотчлайв - может быть
    // IFRAME: true, //нафиг
    // PUBLIC_BROADCAST: true, //всегда есть, расписать гет параметр
    // PUBLIC_BROADCAST_LIST: true, //всегда есть
    // PRIVATE_SESSION: true, //всегда есть, расписать гет параметр (но сейчас инит кола без ид рекваст)
    // WORKING_HOURS:false // if present enable work hours //FIXME check UTC convert
    /*WORKING_HOURS: { //если нету то всегда работают, или указывают раб время.
        /!*1://"Monday"
            {
                open: "1:00",
                close: "20:00"
            },*!/
        2://"Tuesday"
            {
                open: "2:00",
                close: "20:00"
            },
        3://"Wednesday"
            {
                open: "3:00",
                close: "20:00"
            },
        4://"Thursday"
            {
                open: "16:00",
                close: "24:00"
            },
        5://"Friday"
            {
                open: "5:00",
                close: "20:00"
            },
        6://"Saturday"
            {
                open: "6:00",
                close: "20:00"
            },
        0://"Sunday"
            {
                open: "7:00",
                close: "20:00"
            },
    },*/
};

export const GOOGLE_EVENTS_NAME = {
    PRIVATE_SESSION_INIT: 'vtail-call-initialized',
    PRIVATE_SESSION_START: 'vtail-consultant-joined',
    PRIVATE_SESSION_END: 'vtail-call-finished',
    PUBLIC_PRODUCT_DETAIL: 'vtail-open-product',

};

export const CLIENT_STATES = {
    REQUEST_ASAP: "asap_request",
    REQUEST_SCHEDULED: "scheduled_request",
    PRIVATE_SESSION: "private_session",
    NEW: "new",
};

export const BROADCAST_STATUSES_STRING = {
    LIVE: "live",
    UPCOMING: "upcoming",
    COMPLETED: "completed",
};
