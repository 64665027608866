/* eslint-disable */
<template>
  <div class="meeting">
<!--    <yuri>
      <button @click="onSleepTrueClick">sleep true</button>
      <button @click="onSleepFalseClick">sleep false</button>
      <button @click="onSwitchTrueClick">Switch true</button>
      <button @click="onSwitchFalseClick">Switch false</button>
      <div>
        <input placeholder="in msec" ref="animateDelay">
        <button @click="onSetAnimateDelayClick">set_animateDelay</button>

      </div>

    </yuri>-->
    <canvas id="canvasWeboji" class="sidekey-stream-mask"></canvas>
<!--    <div class='webojiSVG'>
      &lt;!&ndash;        <xml version="1.0" encoding="utf-8">&ndash;&gt;
      &lt;!&ndash; Generator: Adobe Illustrator 24.3.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  &ndash;&gt;
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  x="0px" y="0px"
           xml:space="preserve" id="svg_element"
      ></svg>
    </div>-->
    <div class="player">
      <div class="player__users">
        <div class="player__user">
          <div class="player__user_name sidekey-guid__partner"></div>
          <video id="vtail-player-video-remote" class="vtail-video_remote player__user_video" playsinline autoplay></video>
        </div>
        <div class="player__user me">
          <div class="player__user_name">{{ user.user_name }}</div>
          <video id="vtail-player-video-local" class="vtail-video_local player__user_video" playsinline autoplay muted></video>
        </div>
      </div>
      <div class="player__controls">
        <div class="player__controls_buttons">
          <div class="player__button microphone"></div>
          <div class="player__button microphone off"></div>
          <div class="player__button mask"></div>
          <div class="player__button mask off"></div>
          <div class="player__button cancel"></div>
        </div>
        <div class="player__controls_preview">
          <canvas id="jeelizFaceExpressionsCanvas" class="sidekey-stream-user selfview"></canvas>
<!--          <img src="https://www.looper.com/img/gallery/the-real-reason-heath-ledger-licked-his-lips-so-much-in-the-dark-knight/l-intro-1625617080.jpg" alt="" />-->
        </div>
      </div>
    </div>
    <div class="meeting__details">
      <h3>Meeting details</h3>
      <h4>Joining info</h4>
      <div class="meeting__link">
        <div class="meeting__link_val">https://meet.sidekey.com/?id=3a194a4a-2e03-4c57-aab4-7d73ded6357c</div>
        <div class="meeting__link_btn">Copy Joining info</div>
      </div>
      <div class="meeting__security">
        <div class="meeting__security_title">Your meeting is safe and 100% anonymous</div>
        <div class="meeting__security_info">No one can join this meeting unless invited by the host by crypto wallet ID</div>
      </div>
    </div>


  </div>
</template>

<script>

import api from "@/vtail/API";
import {AUTH_USER} from "@/vtail/Auth";
import PrivateSessionClass from "@/vtail/PrivatSession";
import {AVATARS} from "@/vtail/avatars";
import router from "@/router";

let JeelizWebojiSVGHelper = window.JeelizWebojiSVGHelper;
const BASE_DOMAIN = process.env.VUE_APP_VTAIL_BASE_DOMAIN;
export default {
  name:       'Meeting',
  components: {},
  data(){
    return {
      user :AUTH_USER.user
    }
    // this.user =AUTH_USER.user;
  },
  mounted() {
    console.info("Meeting mounted", this.$route.params, AVATARS.length);
    /*let webojiSVG = document.querySelector('.webojiSVG');

    webojiSVG.innerHTML = AVATARS[3];
    let svgElement = webojiSVG.querySelector('svg');
    svgElement.id = 'svg_element';*/
    // this.initJeeliz();
    this.broadcastID = this.$route.params.meetID;
    this.initState ={
      muted: this.$route.params.muted,
      anonym: this.$route.params.anonym,
    }
    console.info("this.broadcastID", this.broadcastID);
    console.info("this.initState", this.initState);
    if (this.broadcastID){
      this.initPrivateSession();
    } else {
      /*api.broadcastsNewPrivateBroadcast(AUTH_USER.user.store_user_id)
          .then(broadcastID => {
            console.info("broadcastsNewPrivateBroadcast", broadcastID);
            this.broadcastID = broadcastID;
            this.initPrivateSession();
          })
          .catch(error => {
            console.error("broadcastsNewPrivateBroadcast", error)
          })*/
    }

    // this.user = AUTH_USER.user;


    /*api.broadcastsNewPrivateBroadcast(AUTH_USER.user.store_user_id)
        .then(broadcastID => {
          console.info("broadcastsNewPrivateBroadcast", broadcastID);
          this.broadcastID = broadcastID;

this.privateSession = new PrivateSessionClass({broadcastID: this.broadcastID});

    this.privateSession.on("close", () => {
      console.info("privateSession on close", this.privateSession.broadcast);
      this.privateSession.destroy()
          .then(() => {
            console.info("privateSession destroy");
            this.privateSession = null;
          })
          .catch(error => {
            console.error("privateSession destroy", error)
          });
    });

    this.initJeeliz();


        })
        .catch(error => {
          console.error("broadcastsNewPrivateBroadcast", error)
        })*/
  },
  updated() {
    console.log("updated", this.$route)
  },

  methods: {
    initPrivateSession(){
      console.info("initPrivateSession", this.broadcastID);

      let shareLink = document.querySelector('.meeting__link_val')
      shareLink.innerHTML = BASE_DOMAIN+'?id='+this.broadcastID;

      document.querySelector('.meeting__link_btn').addEventListener('click', () => {
        console.info("shareLink", shareLink.innerHTML);
        navigator.clipboard.writeText(shareLink.innerHTML)
            .then(() => {
            })
            .catch(err => {
              console.error('Async: Could not copy text: ', err);
            });
      })

      let privateSession = new PrivateSessionClass({broadcastID: this.broadcastID, initState: this.initState});
      // this.drawSVG()
      // document.querySelector('.sidekey-guid.sidekey-guid__me').innerHTML = AUTH_USER.user.user_name;
      privateSession.on("close", () => {
        console.info("privateSession on close", privateSession.broadcast);
        privateSession.destroy()
            .then(() => {
              console.info("privateSession destroy");
              // window.JEELIZFACEEXPRESSIONS.destroy()
              privateSession = null;
              // router.push({ name: 'LeftMeetingPage', params: { meetID: this.broadcastID} });
              setTimeout( () => router.push({ name: 'LeftMeetingPage', params: { meetID: this.broadcastID} }), 500);
            })
            .catch(error => {
              console.error("privateSession destroy", error)
            });
      });
    },

    /*initJeeliz() {
      // this.createDom();
      console.info("!!! JeelizWebojiSVGHelper", JeelizWebojiSVGHelper);
      let _SVGpupilLeft  = document.getElementById('svgPupilLeft');
      let _SVGpupilRight = document.getElementById('svgPupilRight');
      let _SVGhead       = document.getElementById('svgHead');
      let _this          = this;

      window.JEELIZFACEEXPRESSIONS.set_size(150,150); //TODO !!!!!

      // window.JEELIZFACEEXPRESSIONS.switch_displayVideo(false);

      window.JEELIZFACEEXPRESSIONS.onWebcamAsk((a)=>{
        console.info("JEELIZFACEEXPRESSIONS ", "onWebcamAsk", a)
      })

      window.JEELIZFACEEXPRESSIONS.onWebcamGet((a)=>{
        console.info("JEELIZFACEEXPRESSIONS ", "onWebcamGet", a)
        // window.JEELIZFACEEXPRESSIONS.set_animateDelay(500);
      })

      window.JEELIZFACEEXPRESSIONS.onContextLost((a)=>{
        console.info("JEELIZFACEEXPRESSIONS ", "onContextLost", a)
      })





      console.info("!!!!", document.getElementById('jeelizFaceExpressionsCanvas'));
      JeelizWebojiSVGHelper.init({
        canvasId:   'jeelizFaceExpressionsCanvas',
        NNCPath:    './js/',
        hysteresis: 0.02, // bonus score for already selected expression. Against flickering
        isMirror:   true,

        expressions: [ // list of uncorrelated expressions (for example the mouth is uncorrelated with the right eye)
          { // mouth. Inside a group each expression is an exclusive choice
            // the key of an expression is its CSS class. the value is the score class
            // the chosen expression is the one which has the higher score
            /!*
            All factors are between 0 and 1. names:
              smileRight -> closed mouth smile right
              smileLeft  -> closed mouth smile left
              eyeBrowLeftDown -> eyebrow left frowned
              eyeBrowRightDown -> eyebrow right frowned
              eyeBrowLeftUp -> eyebrow left up (surprised)
              eyeBrowRightUp -> eyebrow right up (surprised)
              mouthOpen -> mouth open
              mouthRound -> mouth round
              eyeRightClose -> close right eye
              eyeLeftClose  -> close left eye
              mouthNasty   -> mouth nasty (upper lip raised)
            *!/
            svgMouthRound: function (ks) {
              return 0.7 * ks.mouthRound - 0.1 * ks.mouthOpen - 0.2;
            },

            svgMouthOpen: function (ks) {
              return 1.0 * ks.mouthOpen;
            },

            svgMouthRest: function (/!*ks*!/) {
              return 0.45;
            },

            svgMouthNasty: function (ks) {
              return ks.mouthNasty * 2.0 + 0.2 * ks.mouthOpen;
            },

            svgSmileLeft: function (ks) {
              return ks.smileLeft - ks.smileRight;
            },

            svgSmileRight: function (ks) {
              return ks.smileRight - ks.smileLeft;
            },

            svgSmile: function (ks) {
              return (ks.smileRight + ks.smileLeft);
            }
          },

          { // left eye
            svgEyeLeftOpen:  function (ks) {
              return 1. - ks.eyeLeftClose;
            },
            svgEyeLeftClose: function (ks) {
              return 1.5 * ks.eyeLeftClose;
            }
          },

          { // right eye
            svgEyeRightOpen:  function (ks) {
              return 1. - ks.eyeRightClose;
            },
            svgEyeRightClose: function (ks) {
              return 1.0 * ks.eyeRightClose;
            }
          },

          { // left eyebrow
            eyeBrowLeftRest: function (/!*ks*!/) {
              return 0.4;
            },
            eyeBrowLeftUp:   function (ks) {
              return ks.eyeBrowLeftUp;
            },
            eyeBrowLeftDown: function (ks) {
              return 1.0 * ks.eyeBrowLeftDown;
            }
          },

          { // right eyebrow
            eyeBrowRightRest: function (/!*ks*!/) {
              return 0.4;
            },
            eyeBrowRightUp:   function (ks) {
              return ks.eyeBrowRightUp;
            },
            eyeBrowRightDown: function (ks) {
              return 1.0 * ks.eyeBrowRightDown;
            }
          }
        ], //end expressions[]

        rotationCallback: function (xyz) {
          // console.info("rotationCallback");
          const rx = xyz[0], // head angle: rotation around X (look up/down)
                ry = xyz[1], // rotation around Y: look right/left
                rz = xyz[2]; // rotation around Z: head bending

          const dx = 12 * ry, dy = -5 + 20 * rx;
          move_pupil(_SVGpupilRight, dx, dy);
          move_pupil(_SVGpupilLeft, dx, dy);

          rotate_headZ(-rz * 150);
          _this.drawSVG();

          window.JEELIZFACEEXPRESSIONS.switch_sleep(true);

          window.JEELIZFACEEXPRESSIONS.switch_displayVideo(false);
          setInterval(() => {
            _this.drawSVG();
            // console.info("DRAW", this.timestamp);
          }, 100);
        }

      });



      function move_pupil(SVGpupil, dx, dy) {
        // console.info("move_pupil", SVGpupil, dx, dy);
        JeelizWebojiSVGHelper.pos_SVGpath(SVGpupil, dx, dy);
      }

      function rotate_headZ(rz) {
        JeelizWebojiSVGHelper.rot_SVGpath(_SVGhead, rz);
      }
    },*/
    /*drawSVG(){
      let svgElement = document.getElementById('svg_element');
      let {width, height} = svgElement.getBBox();
      // let width = 225;
      // let height = 340;
      // console.info("width, height", width, height);

      let outerHTML = svgElement.outerHTML;
      // console.info("outerHTML", outerHTML);

      let blob = new Blob([outerHTML],{type:'image/svg+xml;charset=utf-8'});
      // console.info("blob", blob);

      let blobURL = URL.createObjectURL(blob);
      // console.info("blobURL", blobURL);


      let image = new Image();
      image.onload = () => {

        // let canvas = document.createElement('canvas');
        let canvasWeboji = document.getElementById('canvasWeboji');

        canvasWeboji.width = width;

        canvasWeboji.height = height;
        let context = canvasWeboji.getContext('2d');
        // draw image in canvas starting left-0 , top - 0
        context.fillStyle = "white";
        context.fillRect(0, 0, width, height);
        context.drawImage(image, 0, 0, width, height );
        console.info("BASE64",canvasWeboji.toDataURL());
        //  downloadImage(canvas); need to implement
      };
      image.src = blobURL;

    },*/

    /*onSleepTrueClick(){
      window.JEELIZFACEEXPRESSIONS.switch_sleep(true);
    },
    onSleepFalseClick(){
      window.JEELIZFACEEXPRESSIONS.switch_sleep(false);
    },
    onSwitchTrueClick(){
      window.JEELIZFACEEXPRESSIONS.switch_displayVideo(true);
    },
    onSwitchFalseClick(){
      window.JEELIZFACEEXPRESSIONS.switch_displayVideo(false);
    },
    onSetAnimateDelayClick(){
      let val = parseInt(this.$refs.animateDelay.value);
      window.JEELIZFACEEXPRESSIONS.set_animateDelay(val)
    },*/
  }
}
</script>
