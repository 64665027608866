<template>
  <footer>
    <router-link to="/" class="logo">Sidekey</router-link>
    <div class="copyright">Copyright ©2021 Sidekey All rights reserved.    Terms  |  Privacy</div>
  </footer>
</template>

<script>
export default {
name: "Footer"
}
</script>

<style scoped>

</style>