const Utils = {
    uuidv4: () => {
        return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
            (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
        );
    },
    generateBroadcastID: (broadcast) => {
        let date = module.exports.getISODate(new Date(broadcast.date.from));
        let name = broadcast.name.trim().replaceAll(' ', '-');
        return date + '_' + name;
    },
    getDayWorkingHours: (localDate, workingHours) => {
        let dayName = module.exports.getEnWeekDayName(localDate);
        let diff = Utils.getTimezoneOffset(localDate, workingHours.timezone);
        console.info("hours", workingHours.week[dayName], workingHours.timezone, diff);
        let [hours, minutes] = workingHours.week[dayName].from.split(':');
        localDate.setHours(hours, minutes, 0);
        let storeOpen = new Date(localDate.getTime() + diff);
        [hours, minutes] = workingHours.week[dayName].to.split(':');
        localDate.setHours((hours == 0) ? 24 : hours, minutes, 0); //TODO FIXME if close at 00.30
        let storeClose = new Date(localDate.getTime() + diff);
        console.info("open",storeOpen);
        console.info("close",storeClose);
        return {open: storeOpen, close: storeClose};
        // return timeStamp>=storeOpen && timeStamp < storeClose
    },
    getEnWeekDayName: (date) => {
        return Intl.DateTimeFormat('en', {weekday: 'long'}).format(date).toLowerCase();
    },
    getTimezoneOffset: (localDate, storeTimezone) => {
        let storeDate = new Date(localDate.toLocaleString('en-US', {
            timeZone: storeTimezone
        }));
        console.info(storeDate);
        let diff = localDate.getTime() - storeDate.getTime();
        return Math.floor(diff / 1000) * 1000;
    },
    getISODate(date) {
        console.info('getISODate', date);
        return new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000).toISOString().split('T')[0];
    },
    /*************************************************/
};
module.exports = Utils;