<template>
  <div class="home">
    <div class="home__content">
      <h1>Sidekey is the first open communication platform </h1>
      <h2>collectively owned and managed by the community it serves</h2>
      <p>
        Connect with Metamask to create your Sidekey meeting
      </p>
      <div class="home__form">
        <button class="button" @click="onNewMeetingClick">New meeting</button>
        <input type="text" class="home__input" placeholder="Enter meeting ID" v-model="meetID"/>
        <button class="button button__transparent" @click="onJoinClick">Join</button>
      </div>
    </div>
    <div class="home__slider">
      <hooper>
        <slide class="slide">
          <div class="slide__img slide__img_1">
            <img src="@/assets/img/slide1.png" alt="" />
          </div>
          <div class="slide__title">
            Frictionless  100% anonymous communication
          </div>
        </slide>
        <slide class="slide">
          <div class="slide__img">
            <img src="@/assets/img/slide2.png" alt="" />
          </div>
          <div class="slide__title">
            Video avatar reflects your facial expressions and movements
          </div>
        </slide>
        <slide class="slide">
          <div class="slide__img">
            <img src="@/assets/img/slide3.png" alt="" />
          </div>
          <div class="slide__title">
            Safe and sequred. Only approved person with ID can join your meeting
          </div>
        </slide>
        <slide class="slide">
          <div class="slide__img">
            <img src="@/assets/img/slide4.png" alt="" />
          </div>
          <div class="slide__title">
            Integration of a crypto wallet through a unique
            ID included
          </div>
        </slide>
        <hooper-pagination slot="hooper-addons"></hooper-pagination>
      </hooper>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import {Hooper, Slide, Pagination as HooperPagination} from 'hooper';
import 'hooper/dist/hooper.css';

import router from "@/router";

import api from "@/vtail/API";
import auth from "@/vtail/Auth";

export default {
  name: 'Home',
  components: {
      Hooper,
      Slide,
      HooperPagination
},
  data(){
    return {
      meetID: null,
    }
  },
  mounted() {
    this.account = {};
    // auth.signOut();
    this.meetID = this.$route.query.id || '';
    if (!window.ethereum) {
      router.push({name: 'MetamaskNotInstalled'});
    }

    if (this.$route.params.tryAgain){
      this.onJoinClick();
    }
  },
  methods:{
    onNewMeetingClick(){
      console.info("onNewMeetingClick");
      this.meetID = null;
      this.requestAccounts();

    },
    onJoinClick(){
      console.info("onJoinClick", this.meetID)
      if (this.meetID.length > 0){
        this.requestAccounts();
      }
    },

    requestAccounts(){
      console.info("requestAccounts");
      window.ethereum.request({method: 'eth_requestAccounts'})  //eth_accounts
          .then(accounts => {
            console.info("eth_requestAccounts", accounts);
            this.account.id = accounts[0];
            this.createClient();
          })
          .catch(error => {
            console.error("eth_requestAccounts", error)
          })
    },

    createClient() {
      console.info("createClient")
      api.usersCreateClient(this.account.id)
          .then(client => {
            console.info("usersCreateClient", client);
            this.signClient(client)
          })
          .catch(error => {
            console.error("usersCreateClient", error)
          })
    },
    signClient(client) {
      console.info("signClient", client);
      const msg = client.nonce;
      // const msg = '0x879a053d4800c6354e76c7985a865d2922c82fb5b3f4577b2fe08b998954f2e0';

      console.info("request", {
        method: 'personal_sign',
        params: [this.account.id, msg],
      })

      window.ethereum.request({
        method: 'personal_sign',
        params: [this.account.id, msg],
      })
          .then(sign => {
            console.info("personal_sign", sign);
            // this.account = accounts[0];
            this.account.sign = sign;
            auth.updateUser({account: this.account});
            this.onPersonalSign();

            // router.push({ name: 'AuthPage', params: { meetID: this.meetID, account:this.account} });
            // this.stepName();
            // this.showNameForm();
            // this.authClient(sign);
          })
          .catch(error => {
            console.error("eth_sign", error)
          })
    },
    onPersonalSign() {
      console.info("onPersonalSign", this.meetID)
      if (this.meetID) {
        api.broadcastsCheckAccessBroadcast(this.meetID, this.account.id)
            .then(response => {
              console.info("broadcastsCheckAccessBroadcast", response);
              if (response.has_access){
                router.push({name: 'AuthPage', params: {meetID: this.meetID, account: this.account}});
              } else {
                router.push({name: 'AccessDenied', params: {meetID: this.meetID}});
              }
            })
            .catch(error => {
              console.error("broadcastsCheckAccessBroadcast", error)
              router.push({name: 'AccessDenied', params: {meetID: this.meetID}});
            })
      } else {
        router.push({name: 'AuthPage', params: {meetID: this.meetID, account: this.account}});
      }
    }
  }
}
</script>
