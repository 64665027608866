import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Meeting from "@/views/Meeting";
import LeftMeeting from "@/views/LeftMeeting";
import CreateRoom from "@/views/CreateRoom";
import AccessDenied from "@/views/AccessDenied";
import MetamaskNotInstalled from "@/views/MetamaskNotInstalled";
import auth, {AUTH_USER} from "@/vtail/Auth";
import GetReady from "@/views/GetReady";

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'HomePage',
    component: Home,
    meta: { nonAuth: true }
  },
  {
    path: '/createRoom',
    name: 'CreateRoomPage',
    component: CreateRoom
  },
  {
    path: '/getReady',
    name: 'GetReadyPage',
    component: GetReady
  },
  {
    path: '/meeting',
    name: 'MeetingPage',
    component: Meeting
  },
  {
    path: '/left-meeting',
    name: 'LeftMeetingPage',
    component: LeftMeeting
  },
  {
    path: '/auth',
    name: 'AuthPage',
    // route level code-splitting
    // this generates a separate chunk (auth.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Auth.vue')
  },
  {
    path: '/access-denied',
    name: 'AccessDenied',
    component: AccessDenied,
    meta: { nonAuth: true }
  },
  {
    path: '/not-installed',
    name: 'MetamaskNotInstalled',
    component: MetamaskNotInstalled,
    meta: { nonAuth: true }
  },
]

const router = new VueRouter({
  mode: 'history',
  hash: false,
  routes
})

router.beforeEach(async (to, from, next) => {
  // canUserAccess() returns `true` or `false`
  console.info("user", AUTH_USER);
  console.info("beforeEach", (to.name !== 'HomePage' && !(AUTH_USER.account || AUTH_USER.token)));
  console.info(to, from)
  if (!to.meta.nonAuth  && !(AUTH_USER.account || AUTH_USER.token)) {
    next({ name: 'HomePage' })
  } else {
    next()
  }
  // next()
})

export default router
