<template>
  <div class="create">
    <h1>Create Room</h1>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et
    </p>
    <div class="create__types">
      <div class="create__item create__item_open">
        <div class="create__header">
          <h3 class="create__title">Open Room</h3>
          <h4 class="create__subtitle">Explanation Lorem ipsum dolor sit amet, consectetur</h4>
        </div>
        <div class="create__content">
          Your meeting is safe and 100% anonymous
        </div>
        <button @click="onCreateOpenRoomClick" class="button">Create Room</button>
      </div>
      <div class="create__item create__item_wallet">
        <div class="create__header">
          <h3 class="create__title">Wallet owner</h3>
          <h4 class="create__subtitle">Explanation Lorem ipsum dolor sit amet, consectetur</h4>
        </div>
        <div class="create__content">
          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore
          </p>
          <input type="text" v-model="walletAddress" class="create__input" placeholder="Enter wallet ID" />
        </div>
        <button @click="onCreateWalletRoomClick" :disabled="!walletAddress" class="button">Create Room</button>
      </div>
      <div class="create__item create__item_token">
        <div class="create__header">
          <h3 class="create__title">Token requirement</h3>
          <h4 class="create__subtitle">Explanation Lorem ipsum dolor sit amet, consectetur</h4>
        </div>
        <div class="create__content">
          <ul class="create__toggle">
            <li @click="onSearchTokenClick('search')"
                class="create__toggle_item"
                :class="{'active': tokenMode === 'search'}">Search</li>
            <li @click="onImportCustomClick('custom')"
                class="create__toggle_item"
                :class="{'active': tokenMode === 'custom'}">Import custom token</li>
          </ul>
          <search-dropdown v-if="tokenMode === 'search' && !currentToken.address"
                           :search-list="tokens"
                           @select="onTokenSelect"></search-dropdown><!--&& tokens.length-->
          <div class="create__custom" v-if="tokenMode ===  'custom' && !currentToken.address">
            <input type="text" placeholder="Token Contract Address" class="create__input" v-model="customToken.address">
<!--            <input type="text" placeholder="Token Quantity" class="create__input" v-model="customToken.count">-->
            <button @click="onCustomToken" class="button button__border" :disabled="!(customToken.address)">Add custom token</button>
          </div>
          <div class="token" v-if="currentToken.address">
            <div class="token__image-wrap" v-if="currentToken.image">
              <img :src="'https://ethplorer.io/'+currentToken.image" alt="" class="token__image" />
            </div>
            <div class="token__name">{{currentToken.name}}</div>
            <div class="token__quantity"  v-if="currentToken.isETH">
              <input type="number" class="create__input" placeholder="Quantity" v-model="currentToken.count"/>
            </div>
            <div class="token__remove" @click="onClearTokenClick"></div>
          </div>
        </div>
        <button @click="onCreateTokenRoomClick"
                :disabled='(!( currentToken.address && ( !currentToken.isETH || (currentToken.isETH && currentToken.count))))'
                class="button">Create Room</button>
      </div>
    </div>
<!--<yuri>
    <div>Open Room
      <button @click="onCreateOpenRoomClick">Next</button>
    </div>
    <div>Wallet
      <input v-model="walletAddress">
      <button @click="onCreateWalletRoomClick">Next</button>
    </div>
    <div>Token
      <div class="custom token">
        <input v-model="customToken.address">
        <input v-model="customToken.count">
        <button @click="onCustomToken">Add</button>
      </div>
      <select v-model="currentToken">
        <option v-for="item in tokens" :value="item" :key="item.address">
&lt;!&ndash;          <img width="50" height="50" :src="'https://ethplorer.io/'+item.image"/>&ndash;&gt; {{item.name}}({{item.symbol}})
        </option>

      </select>
      -{{currentToken.address}}-|{{!!currentToken.isETH}}|{{tokens.length}}
&lt;!&ndash;      <input v-model="room.contractAddress">&ndash;&gt;
&lt;!&ndash;      <input v-model="room.tokensCount">&ndash;&gt;

&lt;!&ndash;      <div v-for="item in tokens" :value="item.address" :key="item.address">
        <img width="50" height="50" :src="'https://ethplorer.io/'+item.image"/> {{item.name}}({{item.symbol}})
      </div>&ndash;&gt;
      <button @click="onCreateTokenRoomClick">Next</button>
    </div>
  </yuri>-->
  </div>
</template>

<script>
import router from "@/router";
import api from "@/vtail/API";

import SearchDropdown from "@/components/SearchDropdown";

export default {
  name: "CreateRoom",
  components: {
    SearchDropdown
  },
  data(){
    return {
      search:          '',
      isSearchVisible: false,
      tokenMode:       'search',
      tokensList:      [],

      walletAddress: null,

      currentToken: {
        address: null,
        name:    null,
        image:   null,
        symbol:  null,
        count:   null,
      },
      customToken:  {
        address: null,
        name:    null,
        image:   null,
        symbol:  null,
        count:   null,
      },
      tokens:       [
        {
          "address": "0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2",
          "name":    "WETH",
          "image":   "/images/weth.png",
          "symbol":  "WETH"
        },
        {
          "address": "0xdac17f958d2ee523a2206206994597c13d831ec7",
          "name":    "Tether USD",
          "image":   "/images/tether.png",
          "symbol":  "USDT"
        },
        {
          "address": "0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48",
          "name":    "USD Coin",
          "image":   "/images/usdc.png",
          "symbol":  "USDC"
        },
        {
          "address": "0x990f341946a3fdb507ae7e52d17851b87168017c",
          "name":    "Strong",
          "image":   "/images/STRONG990f3419.png",
          "symbol":  "STRONG"
        },
        {
          "address": "0xf4d2888d29d722226fafa5d9b24f9164c092421e",
          "name":    "LooksRare",
          "image":   "/images/LOOKSf4d2888d.png",
          "symbol":  "LOOKS"
        },
        {
          "address": "0x6b175474e89094c44da98b954eedeac495271d0f",
          "name":    "Dai",
          "image":   "/images/mcd-dai.png",
          "symbol":  "DAI"
        },
        {
          "address": "0x95ad61b0a150d79219dcf64e1e6cc01f0b64c4ce",
          "name":    "Shiba Inu",
          "image":   "/images/SHIB95ad61b0.png",
          "symbol":  "SHIB"
        },
        {
          "address": "0x7d1afa7b718fb893db30a3abc0cfc608aacfebb0",
          "name":    "Matic Network",
          "image":   "/images/MATIC7d1afa7b.png",
          "symbol":  "MATIC"
        },
        {
          "address": "0x514910771af9ca656af840dff83e8264ecf986ca",
          "name":    "Chainlink",
          "image":   "/images/chainlink.jpg",
          "symbol":  "LINK"
        },
        {
          "address": "0x15d4c048f83bd7e37d49ea4c83a07267ec4203da",
          "name":    "Gala",
          "image":   "/images/GALA15d4c048.png",
          "symbol":  "GALA"
        },
        {
          "address": "0xd533a949740bb3306d119cc777fa900ba034cd52",
          "name":    "Curve DAO Token",
          "image":   "/images/CRVd533a949.png",
          "symbol":  "CRV"
        },
        {
          "address": "0x9b9647431632af44be02ddd22477ed94d14aacaa",
          "name":    "KOK Coin",
          "symbol":  "KOK"
        },
        {
          "address": "0x1c7e83f8c581a967940dbfa7984744646ae46b29",
          "name":    "random",
          "symbol":  "RND"
        },
        {
          "address": "0x16756ec1deb89a2106c35e0b586a799d0a61837d",
          "name":    "Chedda",
          "image":   "/images/CHEDDA16756ec1.jpg",
          "symbol":  "CHEDDA"
        },
        {
          "address": "0xa6dd98031551c23bb4a2fbe2c4d524e8f737c6f7",
          "name":    "Tokenfy",
          "image":   "/images/TKNFYa6dd9803.png",
          "symbol":  "TKNFY"
        },
        {
          "address": "0x8b3192f5eebd8579568a2ed41e6feb402f93f73f",
          "name":    "Saitama Inu",
          "image":   "/images/SAITAMA8b3192f5.png",
          "symbol":  "SAITAMA"
        },
        {
          "address": "0x2b591e99afe9f32eaa6214f7b7629768c40eeb39",
          "name":    "HEX",
          "image":   "/images/HEX2b591e99.png",
          "symbol":  "HEX"
        },
        {
          "address": "0xe89c20096b636ffec9fd26d1a623f42a33ead309",
          "name":    "Oogear",
          "symbol":  "OG"
        },
        {
          "address": "0x0f5d2fb29fb7d3cfee444a200298f468908cc942",
          "name":    "Decentraland",
          "image":   "/images/MANA0f5d2fb2.png",
          "symbol":  "MANA"
        },
        {
          "address": "0x3845badade8e6dff049820680d1f14bd3903a5d0",
          "name":    "SAND",
          "image":   "/images/SAND3845bada.jpg",
          "symbol":  "SAND"
        },
        {
          "address": "0x2260fac5e5542a773aa44fbcfedf7c193bc2c599",
          "name":    "Wrapped Bitcoin",
          "image":   "/images/wbtc.png",
          "symbol":  "WBTC"
        },
        {
          "address": "0x4e15361fd6b4bb609fa63c81a2be19d873717870",
          "name":    "Fantom",
          "image":   "/images/FTM4e15361f.png",
          "symbol":  "FTM"
        },
        {
          "address": "0xcc8fa225d80b9c7d42f96e9570156c65d6caaa25",
          "name":    "Smooth Love Potion",
          "image":   "/images/SLPcc8fa225.png",
          "symbol":  "SLP"
        },
        {
          "address": "0xa47c8bf37f92abed4a126bda807a7b7498661acd",
          "name":    "Wrapped UST Token",
          "symbol":  "UST"
        },
        {
          "address": "0xeb57bf569ad976974c1f861a5923a59f40222451",
          "name":    "Loomi",
          "image":   "/images/LOOMIeb57bf56.jpg",
          "symbol":  "LOOMI"
        },
        {
          "address": "0xcf0c122c6b73ff809c693db761e7baebe62b6a2e",
          "name":    "FLOKI",
          "symbol":  "FLOKI"
        },
        {
          "address": "0xa0b73e1ff0b80914ab6fe0444e65848c4c34450b",
          "name":    "Crypto.com Coin",
          "image":   "/images/CROa0b73e1f.png",
          "symbol":  "CRO"
        },
        {
          "address": "0x8686525d6627a25c68de82c228448f43c97999f2",
          "name":    "Lilly Finance",
          "symbol":  "Ly"
        },
        {
          "address": "0x090185f2135308bad17527004364ebcc2d37e5f6",
          "name":    "Spell Token",
          "image":   "/images/SPELL090185f2.jpg",
          "symbol":  "SPELL"
        },
        {
          "address": "0x4e3fbd56cd56c3e72c1403e103b45db9da5b9d2b",
          "name":    "Convex Finance",
          "image":   "/images/CVX4e3fbd56.png",
          "symbol":  "CVX"
        },
        {
          "address": "0x64aa3364f17a4d01c6f1751fd97c2bd3d7e7f1d5",
          "name":    "Olympus",
          "symbol":  "OHM"
        },
        {
          "address": "0xc0d4ceb216b3ba9c3701b291766fdcba977cec3a",
          "name":    "Redacted Cartel",
          "image":   "/images/BTRFLYc0d4ceb2.jpg",
          "symbol":  "BTRFLY"
        },
        {
          "address": "0x9813037ee2218799597d83d4a5b6f3b6778218d9",
          "name":    "Bone ShibaSwap",
          "image":   "/images/BONE9813037e.png",
          "symbol":  "BONE"
        },
        {
          "address": "0x3b484b82567a09e2588a13d54d032153f0c0aee0",
          "name":    "OpenDAO",
          "image":   "/images/SOS3b484b82.jpg",
          "symbol":  "SOS"
        },
        {
          "address": "0x6b3595068778dd592e39a122f4f5a5cf09c90fe2",
          "name":    "Sushi",
          "image":   "/images/SUSHI6b359506.jpg",
          "symbol":  "SUSHI"
        },
        {
          "address": "0xab167e816e4d76089119900e941befdfa37d6b32",
          "name":    "Shibnobi",
          "image":   "/images/SHINJAab167e81.png",
          "symbol":  "SHINJA"
        },
        {
          "address": "0x1f9840a85d5af5bf1d1762f925bdaddc4201f984",
          "name":    "Uniswap",
          "image":   "/images/UNI1f9840a8.png",
          "symbol":  "UNI"
        },
        {
          "address": "0x1f573d6fb3f13d689ff844b4ce37794d79a7ff1c",
          "name":    "Bancor Network Token",
          "image":   "/images/bancor.png",
          "symbol":  "BNT"
        },
        {
          "address": "0xbbbbca6a901c926f240b89eacb641d8aec7aeafd",
          "name":    "Loopring",
          "image":   "/images/lrc.png",
          "symbol":  "LRC"
        },
        {
          "address": "0x0d8775f648430679a709e98d2b0cb6250d2887ef",
          "name":    "Basic Attention Token",
          "image":   "/images/BAT0d8775f6.png",
          "symbol":  "BAT"
        },
        {
          "address": "0xbb0e17ef65f82ab018d8edd776e8dd940327b28b",
          "name":    "Axie Infinity",
          "image":   "/images/AXSbb0e17ef.png",
          "symbol":  "AXS"
        },
        {
          "address": "0x99d8a9c45b2eca8864373a26d1459e3dff1e17f3",
          "name":    "Magic Internet Money",
          "image":   "/images/MIM99d8a9c4.png",
          "symbol":  "MIM"
        },
        {
          "address": "0x761d38e5ddf6ccf6cf7c55759d5210750b5d60f3",
          "name":    "Dogelon Mars",
          "image":   "/images/ELON761d38e5.jpg",
          "symbol":  "ELON"
        },
        {
          "address": "0xf411903cbc70a74d22900a5de66a2dda66507255",
          "name":    "Verasity",
          "image":   "/images/VRAf411903c.jpg",
          "symbol":  "VRA"
        },
        {
          "address": "0x0ab87046fbb341d058f17cbc4c1133f25a20a52f",
          "name":    "Governance OHM",
          "image":   "/images/GOHM0ab87046.jpg",
          "symbol":  "GOHM"
        },
        {
          "address": "0x8355dbe8b0e275abad27eb843f3eaf3fc855e525",
          "name":    "Wolf Game Wool",
          "image":   "/images/WOOL8355dbe8.jpg",
          "symbol":  "WOOL"
        },
        {
          "address": "0xf57e7e7c23978c3caec3c3548e3d615c346e79ff",
          "name":    "Immutable X",
          "image":   "/images/IMXf57e7e7c.jpeg",
          "symbol":  "IMX"
        },
        {
          "address": "0x6c3f90f043a72fa612cbac8115ee7e52bde6e490",
          "name":    "LP 3pool Curve",
          "image":   "/images/3CRV6c3f90f0.png",
          "symbol":  "3CRV"
        },
        {
          "address": "0xf629cbd94d3791c9250152bd8dfbdf380e2a3b9c",
          "name":    "Enjin Coin",
          "image":   "/images/ENJf629cbd9.png",
          "symbol":  "ENJ"
        },
        {
          "address": "0x7fc66500c84a76ad7e9c93437bfc5ac33e2ddae9",
          "name":    "Aave",
          "image":   "/images/aave.png",
          "symbol":  "AAVE"
        }
      ],

    }
  },
  mounted() {
    this.tokens.sort((a,b) => a.symbol.localeCompare(b.symbol));
    this.tokens.unshift({
      isETH: true,
      "address": "0x0000000000000000000000000000000000000000",
      "name": "Ethereum",
      "image": "/images/eth.png",
      "symbol": "ETH",
    });
  },
  methods: {
    onTokenSelect(newToken) {
      console.info("onTokenSelect", newToken)
      this.currentToken = newToken
    },
    onCreateOpenRoomClick() {
      console.info("onCreateOpenRoomClick");
      this.createRoom();
    },
    onCreateWalletRoomClick() {
      console.info("onCreateWalletRoomClick");
      this.createRoom({
        guest_wallet_address: this.walletAddress.toLowerCase(),
      });
    },
    onCreateTokenRoomClick() {
      console.info("onCreateTokenRoomClick", this.currentToken);
      let data = {}
      if (this.currentToken.isETH) {
        data = {
          count_eth: this.currentToken.count,
        }
      } else {
        data = {
          contract_address: this.currentToken.address,
          count_tokens:     this.currentToken.count,
        }
      }

      this.createRoom(data);
    },
    onCustomToken() {
      console.info("onCustomToken", this.customToken.address, this.customToken.count);
      api.ethplorerGetTokenInfo(this.customToken.address)
          .then(data => {
            console.info("ethplorerGetTokenInfo", data);
            this.currentToken = {
              address: data.address,
              name:    data.name,
              image:   data.image,
              symbol:  data.symbol,
              count:   this.customToken.count,
            }
            /*this.tokens.push({
              address: data.address,
              name:    data.name,
              image:   data.image,
              symbol:  data.symbol,
            })*/
            console.info("COUNT", this.currentToken.count, this.customToken.count);
            console.info("ethplorerGetTokenInfo this.currentToken", this.currentToken);
          })
          .catch(e => {
            console.error("ethplorerGetTokenInfo", e);
            // alert("Token not found");
            this.currentToken = {
              address: this.customToken.address,
              name:    this.customToken.address,
              image:   null,
              symbol:  null,
              count:   null,
            }
          })
    },
    onClearTokenClick(){
      console.info("onClearTokenClick");
      this.currentToken = {};
    },
    createRoom(conditions) {
      console.info("createRoom", conditions);

      api.broadcastsNewPrivateBroadcast(conditions)
          .then(broadcastID => {
            console.info("broadcastsNewPrivateBroadcast", broadcastID);
            // router.push({name: 'MeetingPage', params: {meetID: broadcastID}});
            router.push({name: 'GetReadyPage', params: {meetID: broadcastID}});
          })
          .catch(e => {
            console.error("broadcastsNewPrivateBroadcast", e);
          })

      // router.push({ name: 'MeetingPage', params: { /*meetID: this.$route.params.meetID*/} });
    },
    onImportCustomClick(){
      console.info('onImportCustomClick');
      this.currentToken={};
      this.customToken={};
      this.tokenMode = 'custom'
    },

    onSearchTokenClick() {
      console.info('onSearchTokenClick');
      this.currentToken={};
      this.tokenMode = 'search'
    }
  }
}
</script>

<style scoped>

</style>
