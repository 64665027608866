<template>
  <div class="search-token" @focus.capture="onDropdownToggle(true)" tabindex="0">
    <input v-model="searchString"
           type="text"
           placeholder="Search" ref="search-input"
           class="search-token__input create__input">
    <ul class="search-token__list" v-show="true" ref="search-list" :class="{'open': isDropdownVisible}"> <!-- v-if="isDropdownVisible">-->
      <li v-for="item in searchListFiltered" :key="item.id"
          class="search-token__item" @click="emitSelect(item)">
        <span class="search-token__img" :style="'background-image: url(https://ethplorer.io/' + item.image + ')'"></span>
        <span class="search-token__name">{{ item.name }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "SearchDropdown",
  props: {
    searchList: Array
  },
  data: function () {
    return {
      searchString: null,
      isDropdownVisible: false,
      searchListFiltered: []
    }
  },
  watch: {
    searchString: function () {
      this.searchListFiltered = this.searchList.filter((item) => item.name.toLowerCase().includes(this.searchString.toLowerCase()))
    },
    isDropdownVisible: function () {
    }
  },
  created() {
    const onClickOutside = e => {this.isDropdownVisible = this.$el.contains(e.target) && this.isDropdownVisible;};
    document.addEventListener('click', onClickOutside);
    this.$on('hook:beforeDestroy', () => document.removeEventListener('click', onClickOutside));
  },
  mounted: async function () {
    console.info("mounted", this.searchList);
    this.searchListFiltered = this.searchList
  },
  methods: {
    onDropdownToggle(visibility) {
      this.isDropdownVisible = visibility
    },
    emitSelect: function (selectedToken) {
      this.onDropdownToggle(false);
      this.searchString = selectedToken.name;
      this.$emit('select', selectedToken)
    }
  }
}
</script>

<style scoped>

</style>