<template>
  <div class="access-denied">
    <h1>Access denided</h1>
    <p>
      Sorry about that, but you don’t have permission to join meeting
    </p>
    <div class="access-denied__buttons">
<!--      <button class="button button__border" @click="onCancelClick">Cancel</button>
      <button class="button" @click="onTryClick">Try Again</button>-->

      <button class="button button__border" @click="onCancelClick">Back</button>
    </div>
  </div>
</template>

<script>
import router from "@/router";

export default {
  name: "AccessDenied",
  methods:{
    onCancelClick(){
      console.info('onCancelClick');
      router.push({name: 'HomePage'});
    },
    onTryClick(){
      console.info('onTryClick');
      router.push({name: 'HomePage', query: { id: this.$route.params.meetID }, params: {tryAgain: true}});
    },
  }
}
</script>

<style scoped>

</style>