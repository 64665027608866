<template>
  <div class="not-installed">
    <h1>Lorem ipsum dolor sit amet, consectetur </h1>
    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore</p>
    <div class="not-installed__buttons">
      <a class="button" target="_blank" href="https://metamask.io/download/">Download Metamask extension</a>
      <div class="button button__border">Back to Homepage</div>
    </div>
  </div>
</template>

<script>
export default {
  name: "MetamaskNotInstalled"
}
</script>

<style scoped>

</style>