<template>
  <div id="app">
    <Header :class="{'minimized': minimizeHeader}"></Header>
    <!--<div id="nav">
      <router-link to="/">Home</router-link> |
      <router-link to="/meeting">meeting</router-link> |
      <router-link to="/left-meeting">leftMeeting</router-link> |
      <router-link to="/auth">auth</router-link>
    </div>-->
    <router-view/>
    <Footer v-if="showFooter"></Footer>
  </div>
</template>

<style>
</style>
<script>
import Header from "@/components/Header";
import Footer from "@/components/Footer";

export default {
  components: {Footer, Header},
  data () {
    return {
        showFooter: true,
        minimizeHeader: false
    }
  },
  watch: {
      $route (){
          this.headerFooterVisibility()
      }
  },
  mounted: async function () {
    this.headerFooterVisibility();
  },
  methods: {
      headerFooterVisibility: function () {
          this.minimizeHeader = this.$route.name === 'MeetingPage'
          this.showFooter = this.$route.name === 'HomePage'
      }
  }
}
</script>
