'use strict';

import {EventEmitter} from "events";
import Debug from "./Debug";

const console = new Debug("  WSS  ", "#FFF", "lightblue", true);

export const WSS_CODES = {
    CONNECT_OK: "connect_ok",
    ONLINE_USERS: "online_users",
    ONLINE_USER: "online_user",
    OFFLINE_USER: "offline_user",
    BROADCAST_FINISH: "broadcast_finish",
    BROADCAST_START: "broadcast_start",
    OFFER: "offer",
    ANSWER: "answer",
    CANDIDATE: "candidate",
    NEW_PRODUCT: "new_product",
    NEW_LIKE: "new_like",
    CHAT_MESSAGE: "chat_message",
    REMOVE_MESSAGES: "remove_messages",
    BAN: "ban",
    AUDIO_REQUEST: "audio_request",

    KURENTO_RESPONSE: "response",
    KURENTO_ICECANDIDATE: "iceCandidate",
};
export const AUDIO_REQUEST_STATUSES = {
    ACCEPTED: "accepted",
    DECLINED: "declined",
    NEW: "new",
    DROPPED: "dropped",
};
export const AUDIO_REQUEST_TYPE = {
    CLIENT_AUDIO: "client_audio",
};

const RECONNECTION_ATTEMPTS_NUMBER = 100;
const RECONNECTION_ATTEMPTS_INTERVAL = 1000;

export class WSSClass extends EventEmitter {
    constructor({isKurento = false} = {}) {
        super();
        this.options = {
            isKurento: isKurento,
        };
        console.info("constructor", this.options);
        /*if (WSSClass.Instance) {
            return WSSClass.Instance;
        }
*/
        // this.init(options);
        WSSClass.Instance = this;
        this.SESSION_ID = null;
        this.attemptsNumber = 0;
    }

    connect(url) {
        // this.on('testEvent', this.handleMyEvent);
        console.info("wssUrl", url);
        if (this.wss && this.wss.readyState === WebSocket.OPEN) {
            this.wss.close();
        }
        return new Promise((resolve, reject) => {
            this.wss = new WebSocket(url);
            this.isCorrectClose = false;

// "this" of wss connection can be obtained from this.ws itself
            this.wss.onmessage = (e) => {
                console.info("onmessage",this.options.isKurento, e);
                this.emit("onmessage", e);

                let message = JSON.parse(e.data);
                // console.info("message", message);
                if (this.options.isKurento) {
                    if (message.id && message.type === AUDIO_REQUEST_TYPE.CLIENT_AUDIO) {
                        // console.info("message KURENTO", message);
                        this.emit(message.id, message);
                    }
                } else {
                    if (message.data && message.data.code) {
                        this.emit(message.data.code, message.data.object);
                        if (message.data.code === WSS_CODES.CONNECT_OK) {
                            this.SESSION_ID = message.data.object.session_id;
                            console.info("SESSION_ID", this.SESSION_ID);
                        }
                    }
                }

            };
            this.wss.onerror = (e) => {
                console.info("onerror", e);
                this.emit("onerror", e);
            };
            this.wss.onclose = (e) => {
                console.info("onclose", e);
                this.emit("onclose", e);
                if (!e.wasClean) {
                    this.reconnect(e.currentTarget.url);
                }

            };
            this.wss.onopen = (e) => {
                console.info("onopen", e);
                console.info("wssReady onopen", this.attemptsNumber, e);
                if (this.attemptsNumber) {
                    this.emit("onreconncet", e);
                } else {
                    console.info("EMIT onopen")
                    this.emit("onopen", e);
                    resolve();
                }
                this.attemptsNumber = 0;
            }
        });
    }

    send(data) {
        this.wss.send(JSON.stringify(data));
    }

    reconnect(url) {
        if (this.attemptsNumber < RECONNECTION_ATTEMPTS_NUMBER) {
            ++this.attemptsNumber;
            setTimeout(() => {
                this.connect(url);
            }, RECONNECTION_ATTEMPTS_INTERVAL);
        } else {
            this.emit("connectError");
        }
    }

    disconnect() {
        console.info("disconnect", this.wss.readyState, WebSocket.OPEN);
        if (this.wss && this.wss.readyState === WebSocket.OPEN) {
            return this.wss.close();
        }
        return this;
    }

    close() {
        console.info("close");
        return new Promise((resolve, reject) => {
            this.isCorrectClose = true;
            if (this.wss) {
                this.wss.onmessage = null;
                this.wss.onerror = null;
                this.wss.onclose = null;
                this.wss.onopen = null;
                this.disconnect();
            }
            this.removeListeners(this);
            resolve();
        });
    }

    removeListeners(element) {
        console.info("element.eventNames()", element.eventNames());
        element.eventNames && element.eventNames().forEach(event => {
            element.removeAllListeners(event);
        })
    }

    isConnected() {
        return this.wss && this.wss.readyState === WebSocket.OPEN;
    }
}

const wss = new WSSClass();
export default wss;
